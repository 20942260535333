import React from "react";

import { ShopAmount, PriceBox } from "../StyledElements";

export const ProductPrice = (props) => {
  const { priceDetails } = props;
  const { currency, price, taxPercent } = priceDetails;
  const priceText = `${price * 0.01} ${currency}`;
  const taxString = taxPercent ? `incl. ${taxPercent || "?"}% tax` : "";
  return (
    <PriceBox>
      <ShopAmount>
        <span>{`${priceText}`}</span>
      </ShopAmount>
      <ShopAmount fontSize="0.8rem">
        <span>{`${taxString}`}</span>
      </ShopAmount>
    </PriceBox>
  );
};

export default ProductPrice;
