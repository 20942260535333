import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon, AAButton } from "../StyledElements";

const BuyNowButton = (props) => {
  const navigate = useNavigate();
  const { title = "buy now", width = "10rem" } = props;
  return (
    <AAButton
      onClick={() => navigate("/shop")}
      variant="dark"
      width={width}
    >
      <Icon className="material-icons">{"add_shopping_cart"}</Icon>
      {title}
    </AAButton>
  );
};

export default BuyNowButton;
