import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";

import { Section, Block } from "../ResponsiveElements";
import { Label } from "../StyledElements";

const UnsubscribePage = (props) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const email = query.get("email");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const unsubscribe = async () => {
      // console.log("unsubscribe()", JSON.stringify({ email }, null, 2));
      await API.put("aa-api", `/unsubscribe`, {
        body: { email },
      });
    };
    unsubscribe();
  }, [email]);

  return (
    <Section background="white">
      <Block key="thanks" padding="3rem 0 0 0">
        <Label padding="3rem 0 0 0">your subscription is now cancelled</Label>
      </Block>
    </Section>
  );
};

export default UnsubscribePage;
