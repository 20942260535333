import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Label } from "../StyledElements";

import Order from "./Order";
import CouponForm from "./CouponForm";

const Container = styled.div`
  /* border: 1px solid blue; */
  min-width: 30rem;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    border: 0.1rem solid lightgray;
    border-width: 1px 0 0 0;
    padding: 1rem 0 2rem 1rem;
    &:nth-child(1) {
      padding: 0 0 2rem 1rem;
      border-width: 1;
    }
  }
`;

const OrderList = (props) => {
  const orders = useSelector((state) => state.user.orders);

  const items = orders.map((order, i) => {
    return <Order key={`order-${i}`} order={order} />;
  });

  return (
    <Container>
      <CouponForm />
      <OrderContainer>{items}</OrderContainer>
      {/* {(!orders || orders.length === 0) && (
        <Label>you have no orders yet</Label>
      )} */}
    </Container>
  );
};

export default OrderList;
