import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

import { loadStripe } from "@stripe/stripe-js";
import { API } from "aws-amplify";
import styled from "styled-components";

import StripeCheckoutForm from "./StripeCheckoutForm";

import { cartDetails } from "../../store/reducers/cart/cart-reducer";

import { STRIPE_PUBLISHABLE_KEY } from "../../config";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Container = styled.div`
  border: 1px solid white;
  border-radius: 0.5rem;
  min-width: 20rem;
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
  background-color: white;
`;

const StripeElement = () => {
  const [options, setOptions] = useState({});
  const [clientSecretIsLoading, setClientSecretIsLoading] = useState(true);

  const { total, currency = "eur" } = useSelector(cartDetails);

  useEffect(() => {
    setClientSecretIsLoading(true);
    const onLoad = async () => {
      try {
        const params = {
          amount: (total * 100).toFixed(0),
          currency: currency.toLowerCase(),
        };
        if (process.env.REACT_APP_STAGE === "dev")
          console.log(JSON.stringify({ params }, null, 2));

        // call createStripePaymentIntent():
        const clientSecret = await API.post("aa-api", `/payment`, {
          body: params,
        });
        if (process.env.REACT_APP_STAGE === "dev")
          console.log(JSON.stringify({ clientSecret }, null, 2));
        setOptions({ clientSecret });
        setClientSecretIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container key="container">
      {"clientSecret" in options && (
        <Elements stripe={stripePromise} options={options}>
          <StripeCheckoutForm clientSecretIsLoading={clientSecretIsLoading} />
        </Elements>
      )}
    </Container>
  );
};

export default StripeElement;
