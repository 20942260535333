import React from "react";
import styled from "styled-components";

import { pathToS3Bucket } from "../../config";
import { Headline } from "../ResponsiveElements";

const Container = styled.div`
  width: 100%;
  max-width: 25rem;
  flex-wrap: wrap;
  height: 15rem;
  padding: 1rem;
`;

const Content = styled.div`
  display: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (max-width: 650px) {
    > * {
      &:nth-child(1) {
      justify-content: center;
    }
  }
  }
`;

const Image = styled.img`
  object-fit: scale-down;
  max-width: 50%;
  flex: 1;
  @media (max-width: 37.5em) {
    height: auto;
  }
`;

const PlayIcon = styled.div`
  width: 5rem;
  > * {
    font-size: 5rem;
  }
`;

const VidContainer = styled.div`
  /* border: 1px solid green; */
  flex: 1;
`;

const InnerContent = styled.div`
  height: 100%;
  position: relative;
`;

const VidContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: lightgray;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(170deg, #0d1f33 0%, #0d1f33 1%, #8fccc7);
`;

const VidOverlay = styled.div`
  /* border: 1px solid white; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  color: lightgray;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    cursor: pointer;
    > * {
      color: white;
    }
  }
`;

const VideoCard = (props) => {
  const {
    onClick,
    headline = "no headline",
    title = "no title",
    product,
  } = props;
  const buttonImageUrl = `${pathToS3Bucket}/${product}-web/${product}_video_button.png`;

  return (
    <Container onClick={onClick}>
      <Content>
        <Headline fontSize="1.0rem" fontWeight="normal" padding="0.5rem 0">
          {headline.toUpperCase()}
        </Headline>

        <VidContainer>
          <InnerContent>
            <VidContent key="content">
              <Image src={buttonImageUrl} alt={"PlugIn Picture"} />
            </VidContent>

            <VidOverlay key="overlay">
              <Headline
                fontSize="1.2rem"
                fontWeight="normal"
                padding="1.1rem 0 1.5rem 0"
              >
                {title.toUpperCase()}
              </Headline>
              <PlayIcon>
                <i className={"material-icons"}>{"play_circle_outline"}</i>
              </PlayIcon>
            </VidOverlay>
          </InnerContent>
        </VidContainer>
      </Content>
    </Container>
  );
};

export default VideoCard;
