import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Link, InfoText } from "../StyledElements";

import { Section, Block } from "../ResponsiveElements";

const Headline = styled.div`
  font-size: 2rem;
  padding: 3rem 0;
`;

const Span = styled.div`
  border: 1px solid transparent;
  padding: 0;
`;

const Row = styled.div`
  /* border: 1px solid red; */
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
`;

const ThankYou = (props) => {
  const navigate = useNavigate();

  return (
    <Section background="white">
      <Block key="thanks" padding="3rem 0 0 0">
        <Headline>thank you!</Headline>
        <InfoText>
          <Row>
            <Span>Please head over to your</Span>
            <Link
              key="terms"
              padding="0 0 0 3px"
              onClick={() => navigate("/dashboard/licenses")}
            >
              {"account"}
            </Link>
          </Row>
          <Row>
            <Span>to find your new licenses.</Span>
          </Row>
        </InfoText>
      </Block>
    </Section>
  );
};

export default ThankYou;
