import React from "react";

import DownloadLink from "../shared/DownloadLink";
import Icon from "../shared/Icon";

import {
  Headline,
  Block,
  ThreeColumns,
  OneColumn,
  InfoLine,
} from "../ResponsiveElements";

const ProductFooter = (props) => {
  const { downloads } = props;

  return (
    <Block key="product-footer">
      <ThreeColumns key="download-and-tech">
        <OneColumn key="one">
          <Headline key="downloads">downloads:</Headline>
          {downloads.map(({ icon, title, downloadId }) => (
            <DownloadLink
              key={downloadId}
              icon={icon}
              title={title}
              downloadId={downloadId}
            />
          ))}
        </OneColumn>
        <OneColumn key="two">
          <Headline key="downloads">plug-in formats:</Headline>
          <InfoLine>
            <Icon name="fa-apple" />
            <div>AU, VST, VST3</div>
          </InfoLine>
          <InfoLine>
            <Icon name="fa-windows" />
            <div>VST, VST3</div>
          </InfoLine>
        </OneColumn>
        <OneColumn key="three">
          <Headline key="downloads">tech requirements:</Headline>
          <InfoLine>
            <Icon name="fa-apple" />
            <div>OSX 10.11 or higher</div>
          </InfoLine>
          <InfoLine>
            <Icon name="fa-windows" />
            <div>Windows 10 or higher</div>
          </InfoLine>
          <InfoLine>host audio software (daw)</InfoLine>
          <InfoLine>1 GHz CPU or faster</InfoLine>
          <InfoLine>1000 × 600 or larger display</InfoLine>
        </OneColumn>
      </ThreeColumns>
    </Block>
  );
};

export default ProductFooter;
