import React from "react";
import { useNavigate } from "react-router-dom";

import RegisterForm from "./RegisterForm";

import { FormBox } from "../StyledElements";
import { Section, Block } from "../ResponsiveElements";

const Register = (props) => {
  const navigate = useNavigate();

  const afterRegister = (email) => {
    navigate(`/confirm?email=${email}`);
  };

  return (
    <Section background="white">
      <Block>
        <FormBox>
          <RegisterForm afterRegister={afterRegister} />
        </FormBox>
      </Block>
    </Section>
  );
};

export default Register;
