import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { AAButton } from "../StyledElements";
import Icon from "../shared/Icon";
import { handleDownload } from "../../helpers/helpers";
import { getUserId } from "../../store/reducers/user/user-reducer";
import {
  currentDownloads,
  setDownloadIsActive,
  setDownloadIsDone,
} from "../../store/reducers/app/app-reducer";
import Spinner from "./Spinner";

const DownloadDemoButton = (props) => {
  const dispatch = useDispatch();

  const userId = useSelector(getUserId);
  const activeDownloads = useSelector(currentDownloads);
  const { label, downloadId, width = "10rem", icon = "" } = props;

  const isLoading = activeDownloads.includes(downloadId);

  const onClick = async () => {
    dispatch(setDownloadIsActive(downloadId));
    await handleDownload({ downloadId, userId });
    dispatch(setDownloadIsDone(downloadId));
  };

  return (
    <AAButton
      onClick={isLoading ? () => {} : onClick}
      variant="secondary"
      width={width}
      margin="0 0.5rem"
    >
      {isLoading ? (
        <Spinner margin="0 0.4rem 0 0.8rem" variant="secondary" />
      ) : (
        <Icon name={icon} />
      )}
      {label}
    </AAButton>
  );
};

export default DownloadDemoButton;
