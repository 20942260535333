import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Spinner from "../shared/Spinner";
import { Section, Block } from "../ResponsiveElements";

import {
  FormBox,
  FormInput,
  LabelAndError,
  AAButton,
  SignContainer,
  SignButtonRow,
  TextLine,
} from "../StyledElements";

const ResetPassword = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const email = query.get("email") || "";

  const initialValues = { email };

  const validate = (values) => {
    const errors = {};
    // eslint-disable-next-line
    const emailIsValied = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      values.email,
    );
    if (!emailIsValied) {
      errors.email = "email not valid";
      setErrorMessage(errors.email);
    } else {
      setErrorMessage("");
    }
    return errors;
  };

  const onSubmit = async (values, params) => {
    try {
      const { email } = values;
      await Auth.forgotPassword(email);
      navigate(`/new-password?email=${email}`);
    } catch (error) {
      setErrorMessage(error.code);
    }
  };

  return (
    <Section background="white">
      <Block padding="1rem 2rem 1rem 1rem">
        <FormBox>
          <SignContainer>
            <TextLine padding="0 0 2rem 0">
              {"to choose a new password, please enter your email address and we will send you a confirmation code"}
            </TextLine>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
              }) => {
                const disabled =
                  isSubmitting || !isValid || !dirty || !!errorMessage;
                return (
                  <>
                    <LabelAndError label={"email"} error={errorMessage} />
                    <FormInput
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      className="data-hj-allow"
                    />
                    <SignButtonRow>
                      <AAButton
                        onClick={handleSubmit}
                        variant={isValid && dirty ? "secondary" : "outline"}
                        width="12rem"
                        disabled={disabled}
                      >
                        {!isSubmitting ? "reset password" : <Spinner />}
                      </AAButton>
                    </SignButtonRow>
                  </>
                );
              }}
            </Formik>
          </SignContainer>
        </FormBox>
      </Block>
    </Section>
  );
};

export default ResetPassword;
