import { configureStore } from "@reduxjs/toolkit";

import cartReducer from "./reducers/cart/cart-reducer";
import userReducer from "./reducers/user/user-reducer";
import pricesReducer from "./reducers/prices/prices-reducer";
import appReducer from "./reducers/app/app-reducer";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
    prices: pricesReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;
