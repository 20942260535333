import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

import { Headline } from "../ResponsiveElements";

const Container = styled.li`
  padding: 1rem;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const CompatibilityInfo = (props) => {
  const { product = "" } = props;
  return (
    <Container key={"my-key"}>
      <Icon name="fa-apple" width="4rem" fontSize="3x" />
      <Headline fontSize="1.8rem" padding="1.2rem 0">
        Apple Silicon support
      </Headline>
      <Headline fontSize="1.2rem">
        {`${product} runs native on Apple Silicon Macs`}
      </Headline>
    </Container>
  );
};

export default CompatibilityInfo;
