import config from "../config";
import axios from "axios";

export const handleDownload = async ({ downloadId, userId }) => {
  // Bei einem Amplify API.get() gibt es einen Fehler, wenn der User nicht eingeloggt ist.
  // Deshalb nutzen wir hier axios.
  const path = `${config.apiGateway.URL}/download?downloadId=${downloadId}${
    userId ? `&userId=${userId}` : ""
  }`;
  const { data: signedUrl } = await axios.get(path);
  window.location.href = signedUrl;
};

export const generateInvoiceNumber = () => {
  const invoiceNumber = Math.floor(Math.random() * 100000000000000)
    .toString()
    .substring(0, 10);
  return invoiceNumber;
};
