import React from "react";
import ReactPlayer from "react-player";

import Tag from "../shared/Tag";
import BuyNowButton from "../shared/BuyNowButton";
import PlugInFeature from "../shared/PluginFeature";
import DownloadDemoButton from "../shared/DownloadDemoButton";
import CompatibilityInfo from "../shared/CompatibilityInfo";
import ProductFooter from "./ProductFooter";
import { downloadIds } from "../../download-ids";
import { pathToS3Bucket } from "../../config";

import {
  Section,
  Headline,
  Block,
  ButtonRow,
  AAImage,
  VideoImage,
} from "../ResponsiveElements";

const clockFeatures = [
  {
    title: "CLOCK MODE",
    description: `
    You can choose speeds from 1/4 to 1/32 and let DFAM play in many directions: forward, backward, loop or random. Every time you restart your DAW, DFAM starts in the right place.
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-clock-02.png`,
    alt: "play dfam clock speed",
    maxHeight: "8rem",
    imageRight: false,
  },
  {
    title: "CLOCK LATENCY COMPENSATION",
    description: `
    To compensate for any latencies in your hardware devices, you can advance or delay the clock.
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-clock-details-delay.png`,
    alt: "play dfam clock latency",
    maxHeight: "8rem",
    imageRight: true,
  },
  {
    title: "START NEXT BEAT OR BAR",
    description: `
    If the DAW is started at an odd song position, PlayDFAM can wait for the next beat or the next bar to start the sequencer (new in Version 1.1)
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-clock-details-start-next-bar-beat.png`,
    alt: "play dfam start next beat or bar",
    maxHeight: "8rem",
    imageRight: true,
  },
];

const midiFeatures = [
  {
    title: "MIDI MODE",
    description: `
    In Midi mode, a Midi key is assigned to each of the 8 DFAM sequencer steps. You can simply play DFAM using these Midi keys!
    To create a very unique sequence, you can record the Midi notes onto a track of your DAW. Or you could use a Midi generator or a Midi arpeggiator in your DAW to trigger DFAM's steps via Midi notes.
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-midi-mode.png`,
    alt: "play dfam midi mode",
    maxHeight: "17rem",
    imageRight: false,
  },
  {
    title: "MIDI ARPEGGIO MODE",
    description: `
    This mode is like the Clock & Hold mode, but no steps are played at all until you press at least one MIDI key.
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-midi-arpeggio-mode.png`,
    alt: "play dfam midi arpeggio mode",
    maxHeight: "5.5rem",
    imageRight: true,
  },
  {
    title: "CLOCK & HOLD MODE",
    description: `
    While the sequencer is running, you can hold individual steps via Midi keys. If you press several Midi keys at the same time, DFAM plays them as a new sequence.
      `,
    imageUrl: `${pathToS3Bucket}/playdfam-web/play-dfam-clock-and-hold-mode.png`,
    alt: "play dfam clock & hold mode",
    maxHeight: "5.5rem",
    imageRight: false,
  },
];

const playDfamDownloads = [
  {
    icon: "fa-apple",
    title: `SyncDFAM ${downloadIds.syncdfam.currentVersion} (MAC)`,
    downloadId: downloadIds.syncdfam.installer.mac,
  },
  {
    icon: "fa-windows",
    title: `SyncDFAM ${downloadIds.syncdfam.currentVersion} (WIN)`,
    downloadId: downloadIds.syncdfam.installer.win,
  },
  {
    icon: "fa-book",
    title: "PlayDFAM & SyncDFAM manual",
    downloadId: downloadIds.syncdfam.manual.english,
  },
];

const CTABUttonsPlayDfam = () => (
  <ButtonRow>
    <BuyNowButton key="buy-button" title="buy 59 | 59$" />
  </ButtonRow>
);

const CTABUttonsSyncDfam = () => (
  <ButtonRow doNotHide>
    <DownloadDemoButton
      width="13rem"
      icon="fa-apple"
      label={`SyncDFAM ${downloadIds.syncdfam.currentVersion}`}
      downloadId={downloadIds.syncdfam.installer.mac}
    />
    <DownloadDemoButton
      width="13rem"
      icon="fa-windows"
      label={`SyncDFAM ${downloadIds.syncdfam.currentVersion}`}
      downloadId={downloadIds.syncdfam.installer.win}
    />
  </ButtonRow>
);

const PlayDFAM = () => {
  return (
    <>
      <Section key="section-hero" color="white">
        <Block key="play-dfam-headline">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="2rem 0">
            PlayDFAM
          </Headline>

          <Headline padding="0 0 2rem 0">
            play and sync your MOOG DFAM to your DAW
          </Headline>

          <CTABUttonsPlayDfam key="cta-buttons" />
        </Block>

        <Block key="play-image">
          <AAImage
            key="hero-image"
            maxWidth="40rem"
            src={`${pathToS3Bucket}/playdfam-web/play-dfam-gui-01-v1-1.png`}
            alt="sync-dfam"
          />
        </Block>

        <Block key="sync-dfam-headline">
          <Headline
            fontSize="2.0rem"
            fontWeight="bold"
            padding="2rem 0 1rem 0"
            key="main-headlin"
          >
            SyncDFAM
          </Headline>

          <Headline padding="1rem 0 0rem 0" key="sub-headline">
            PlayDFAM's free little sibling
          </Headline>
        </Block>


        <Block key="sync-image">
          <AAImage
            key="hero-image"
            maxWidth="40rem"
            src={`${pathToS3Bucket}/syncdfam-web/sync-dfam-gui-01.png`}
            alt="sync-dfam"
          />
        </Block>
        <Tag name="free download" margin="0 0 2rem 0" />
        <CTABUttonsSyncDfam key="cta-buttons" />
      </Section>

      <Section key="play-dfam-modules" background="none">
        <Block key="modules">
          <Headline
            key="headline"
            fontSize="1.5rem"
            fontWeight="bold"
            padding="4rem 0 1rem 0"
          >
            PlayDFAM
          </Headline>
        </Block>

        <Block key="video-player">
          <VideoImage>
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                controls
                width="100%"
                height="100%"
                url="https://youtu.be/CgsdH14cAcA"
              />
            </div>
          </VideoImage>
        </Block>

        <Block key="infos">
          <Headline
            key="description-1"
            fontSize="1.0rem"
            padding="0 0 1rem 0"
            maxWidth="60rem"
          >
            You have got a Moog DFAM and want to synchronize it to your DAW?
            Well, that's a bit tricky because DFAM doesn't have Midi and only
            offers an ADV/CLOCK input for external synchronization. This allows
            you to trigger the next step, but unfortunately there is no reset
            input to restart the sequence from the beginning.
          </Headline>
          <Headline
            key="description-2"
            fontSize="1.0rem"
            padding="0 0 1rem 0"
            maxWidth="60rem"
          >
            This is where PlayDFAM comes to the rescue. With PlayDFAM you can
            make your Moog DFAM run perfectly synchronized to your DAW. You can
            even trigger the individual DFAM steps with a Midi keyboardYou only
            need a DC-Coupled Audio Interface to connect DFAM to your Computer.
          </Headline>
        </Block>

        <Block key="modules-clock-list">
          {clockFeatures.map((feature, i) => (
            <PlugInFeature key={`feature-${i}`} content={feature} />
          ))}
        </Block>

        <Block key="modules-midi-list">
          {midiFeatures.map((feature, i) => (
            <PlugInFeature key={`feature-${i}`} content={feature} />
          ))}
        </Block>

        <CTABUttonsPlayDfam key="cta-buttons" />
      </Section>

      <Section key="compatibility-info" background="none">
        <Block padding="2rem 1rem 4rem 1rem">
          <CompatibilityInfo product="PlayDFAM" />
        </Block>
      </Section>

      <Section key="download-and-tech" color="white">
        <ProductFooter key="obelisk-footer" downloads={playDfamDownloads} />
      </Section>
    </>
  );
};

export default PlayDFAM;
