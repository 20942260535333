import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon, AAButton } from "../StyledElements";

const GoBackButton = (props) => {
  const navigate = useNavigate();
  return (
        <AAButton
          onClick={() => navigate(-1)}
          variant="light"
          width="9rem"
        >
          <Icon className="material-icons">
            {"arrow_back"}
          </Icon>
          {"go back"}
        </AAButton>
  );
};

export default GoBackButton;
