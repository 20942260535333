import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";

import { textColor } from "../StyledElements";

import logo from "../../aa-logo.svg";

import Icon from "../shared/Icon";

import { clearUserProfile } from "../../store/reducers/user/user-reducer";
import { clearPrices } from "../../store/reducers/prices/prices-reducer";
import { numberOfItemsInCart } from "../../store/reducers/cart/cart-reducer";

const NavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4.5rem;
  min-height: 4rem;

  border: 1px solid black;
  border-width: 0 0 1px 0;
`;

const Container = styled.div`
  /* border: 1px solid red; */
  flex: 1;
  max-width: 80rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0.2rem 1rem;
`;

const GroupLeft = styled.div`
  /* border: 1px solid green; */
  display: flex;
  @media (max-width: 900px) {
    > * {
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        display: none;
      }
    }
  }
`;

const GroupRight = styled.div`
  /* border: 1px solid green; */
  display: flex;
`;

const NavigationButton = styled.div`
  /* border: 1px solid green; */
  display: flex;
  padding: 0 0.4rem;
  padding: ${(props) => props.padding || "0 0.4rem"};
  margin: ${(props) => props.margin || "0"};
  align-items: center;

  color: ${textColor["primary"]};
  border: 3px solid transparent;
  border-width: 0 0 4px 0;
  border-radius: 0.2rem;
  border-color: ${(props) =>
    props.isSelected ? textColor["primary"] : "transparent"};

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
  > * {
    &:nth-child(2) {
      padding: 0 0 0 0.3rem;
    }
  }
  @media (max-width: 35rem) {
    > * {
      &:nth-child(2) {
        color: red;
        display: none;
      }
    }
  }
`;

const ShopItemsCounter = styled.span`
  padding: 0 0 0 0.5rem;
  /* font-weight: bold; */
`;

const Navigation = (props) => {
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.user.isAuth);
  const cartItemCount = useSelector(numberOfItemsInCart);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLogout = async () => {
    await Auth.signOut();
    dispatch(clearUserProfile());
    dispatch(clearPrices());
    // TODO: clear all other store?
  };

  return (
    <NavigationWrapper>
      <Container>
        <GroupLeft key="left">
          <NavigationButton onClick={() => navigate("/")}>
            <img src={logo} alt="logo" width="80" />
          </NavigationButton>

          {process.env.REACT_APP_SHOW_NEW_PRODUCT === "true" && (
          <NavigationButton
            key="sync-dfam"
            margin="0 0 0 1.5rem"
            onClick={() => navigate("/playdfam")}
            isSelected={pathname.startsWith("/playdfam")}
          >
            <span>PlayDFAM</span>
          </NavigationButton>
          )}

          <NavigationButton
            key="obelisk"
            margin="0 0 0 1.5rem"
            onClick={() => navigate("/obelisk")}
            isSelected={pathname.startsWith("/obelisk")}
          >
            <span>OBELISK</span>
          </NavigationButton>

          <NavigationButton
            key="quartz"
            margin="0 0 0 1.5rem"
            onClick={() => navigate("/quartz")}
            isSelected={pathname.startsWith("/quartz")}
          >
            <span>QUARTZ</span>
          </NavigationButton>

          <NavigationButton
            key="effectbundle"
            margin="0 0 0 1.5rem"
            onClick={() => navigate("/effectbundle")}
            isSelected={pathname.startsWith("/effectbundle")}
          >
            <span>EFFECT BUNDLE</span>
          </NavigationButton>
        </GroupLeft>

        <GroupRight key="right" margin="0 0 0 1rem">
          <NavigationButton
            key="shop"
            margin="0 1.5rem 0 0"
            padding="0.3rem 0.4rem"
            onClick={() => navigate("/shop")}
            isSelected={pathname.startsWith("/shop")}
          >
            <Icon name="shopping_cart" fontSize="1.8rem" width="auto" />
            <span>shop</span>
            {cartItemCount > 0 && <ShopItemsCounter>{`(${cartItemCount})`}</ShopItemsCounter>}
          </NavigationButton>

          {isAuth && (
            <NavigationButton
              key="account"
              margin="0 1.5rem 0 0"
              padding="0.3rem 0.4rem"
              isSelected={pathname.startsWith("/dashboard")}
              onClick={() => navigate("/dashboard/account")}
            >
              <Icon name="portrait" fontSize="1.8rem" width="auto" />
              <span>account</span>
            </NavigationButton>
          )}

          {!isAuth && (
            <NavigationButton
              key="logout"
              onClick={() => navigate("login")}
            >
              <Icon name="login" fontSize="1.8rem" width="1.8rem" />
              <span>login</span>
            </NavigationButton>
          )}
          {isAuth && (
            <NavigationButton key="logout" onClick={handleLogout}>
              <Icon name="logout" fontSize="1.8rem" width="1.8rem" />
              <span>logout</span>
            </NavigationButton>
          )}
        </GroupRight>
      </Container>
    </NavigationWrapper>
  );
};

export default Navigation;
