import React, { useState } from "react";

import Tag from "../shared/Tag";
import PlugInFeature from "../shared/PluginFeature";

import BuyNowButton from "../shared/BuyNowButton";
import VideoCard from "../shared/VideoCard";
import VideoPlayerModal from "../shared/VideoPlayerModal";
import DownloadDemoButton from "../shared/DownloadDemoButton";
import CompatibilityInfo from "../shared/CompatibilityInfo";

import ProductFooter from "./ProductFooter";
import { downloadIds } from "../../download-ids";
import { pathToS3Bucket } from "../../config";

import {
  Section,
  Headline,
  Block,
  FeatureRow,
  ButtonRow,
  AAImage,
  VideoBlock,
} from "../ResponsiveElements";

const quartzFeatures = [
  {
    title: "PANNING AND GAIN",
    description: `
    The AUTO-PANNING and AUTO-GAIN effects can turn the simplest sounds into the coolest groovy gated rhythmic patterns.
  `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_pan_gain.png`,
    alt: "Pan and Gain",
    maxHeight: "23rem",
    imageRight: false,
  },
  {
    title: "FILTER",
    description: `
    The two QUARTZ FILTERs give you a choice of traditional filter effects such as lowpass, highpass and bandpass in combination with high quality oversampling modes. These FILTER twins let you tweak your sounds from soft lowpass or highpass filtering to hard distorted self-resonant filter sounds.
    `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_filter.png`,
    alt: "Filter",
    maxHeight: "23rem",
    imageRight: true,
  },
  {
    title: "DELAY",
    description: `
    A tempo-syncable linkable STEREO DELAY with up to 4 seconds of delay time provides you with all the spacial control you would expect. Modulating the delay time and delay feedback let's you create the weirdest dub- and tape-delay effects.
    `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_delay.png`,
    alt: "Delay",
    maxHeight: "23rem",
    imageRight: false,
  },
  {
    title: "SHAPER",
    description: `
    The QUARTZ SHAPER is a classic wave shaper module. It can be used to add extra distortion and saturation effects to your sounds.
    `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_shaper.png`,
    alt: "Shaper",
    maxHeight: "23rem",
    imageRight: true,
  },
];

const quartzModulations = [
  {
    title: "LINE EDITOR",
    description: `
    The MODULATION LINE EDITOR let's you create your own automated parameter modulations. Each MODULATION LINE can have up to 100 points. By using the quick-access browser you can search a huge library of  MODULATION LINE templates. This gives you a great starting point to create your own amazing effects.
    `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_line_editor.png`,
    alt: "Line Editor",
    maxHeight: "23rem",
    imageRight: false,
  },
  {
    title: "LFOs",
    description: `
    QUARTZ has 4 independent, tempo-syncable LFOs to generate modulation-frequencies from 0.01Hz up to 2 kHz.
    `,
    imageUrl: `${pathToS3Bucket}/quartz-web/quartz_feature_lfos.png`,
    alt: "LFOs",
    maxHeight: "23rem",
    imageRight: true,
  },
];

const quartzDownloads = [
  {
    icon: "fa-apple",
    title: `QUARTZ Demo ${downloadIds.quartz.currentVersion} (MAC)`,
    downloadId: downloadIds.quartz.demoInstaller.mac,
  },
  {
    icon: "fa-windows",
    title: `QUARTZ Demo ${downloadIds.quartz.currentVersion} (WIN)`,
    downloadId: downloadIds.quartz.demoInstaller.win,
  },
  {
    icon: "fa-book",
    title: "manual (english)",
    downloadId: downloadIds.quartz.manual.english,
  },
  {
    icon: "fa-book",
    title: "manual (german)",
    downloadId: downloadIds.quartz.manual.german,
  },
];

const CTABUttons = () => (
  <ButtonRow>
    <BuyNowButton key="buy-button" title="buy 79€ | 89$" />
    <DownloadDemoButton
      icon="fa-apple"
      label={`Demo ${downloadIds.quartz.currentVersion}`}
      downloadId={downloadIds.quartz.demoInstaller.mac}
    />
    <DownloadDemoButton
      icon="fa-windows"
      label={`Demo ${downloadIds.quartz.currentVersion}`}
      downloadId={downloadIds.quartz.demoInstaller.win}
    />
  </ButtonRow>
);

const Quartz = () => {
  const [videoUrl, setVideoUrl] = useState("");

  const openVideoPlayer = (url) => {
    setVideoUrl(url);
  };
  const closeVideoPlayer = () => {
    setVideoUrl("");
  };

  return (
    <>
      {videoUrl && (
        <VideoPlayerModal url={videoUrl} onClose={closeVideoPlayer} />
      )}

      <Section key="hero" color="white">
        <Block key="headline">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="2rem 0">
            QUARTZ
          </Headline>
          <Headline padding="0 0 1rem 0">
            multi effect that turns your sounds into ever evolving rhythmic
            soundscapes
          </Headline>
          <CTABUttons key="cta-buttons" />
        </Block>

        <Block key="image">
          <AAImage
            key="hero-image"
            maxWidth="70rem"
            src={`${pathToS3Bucket}/quartz-web/Quartz_gui1.png`}
            alt="quartz"
          />
          <FeatureRow key="new-features" padding="2rem 0">
            <Tag name="New" />
            <div>editor scalable up to 200%</div>
            <div>runs native on Apple Silicon Macs</div>
          </FeatureRow>
        </Block>
      </Section>

      <Section key="modules" background="none">
        <Block key="effect-modules">
          <Headline
            key="headline"
            fontSize="1.5rem"
            fontWeight="bold"
            padding="4rem 0 1rem 0"
          >
            QUARTZ MODULES
          </Headline>
          <Headline key="description" fontSize="1.0rem" padding="0 0 4rem 0">
            QUARTZ has a simple but clever collection of switchable effect
            modules:
          </Headline>
        </Block>

        <Block key="modules-list">
          {quartzFeatures.map((feature, i) => (
            <PlugInFeature key={`feature-${i}`} content={feature} />
          ))}
        </Block>
      </Section>

      <Section key="modulation" background="none">
        <Block key="modulation-headline">
          <Headline
            key="headline"
            fontSize="1.5rem"
            fontWeight="bold"
            padding="4rem 0 1rem 0"
          >
            QUARTZ MODULATION
          </Headline>
          <Headline key="description" fontSize="1.0rem" padding="0 0 4rem 0">
            With its 4 highclass LFOs and its 4 unique MODULATION LINES, QUARTZ
            has some powerful tools for parameter modulation. The LFOs and the
            MODULATION LINEs can modulate every important parameter in QUARTZ.
          </Headline>
        </Block>
        <Block key="quartzModulations-details">
          {quartzModulations.map((feature, i) => (
            <PlugInFeature key={`feature-${i}`} content={feature} />
          ))}
        </Block>
      </Section>

      <Section key="video-headline" background="none">
        <Headline key="title" fontSize="1.5rem" fontWeight="bold" padding="0">
          VIDEOS
        </Headline>
      </Section>

      <Section key="videos" background="none">
        <Block key="main-video" padding="1rem 1rem 2rem 1rem">
          <VideoBlock key="video-1-2">
            <VideoCard
              product="quartz"
              headline="[1] QUARTZ introduction"
              title="QUARTZ INTRODUCTION"
              key="video-01"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/quartz-web/videos/Quartz_Video_01_Intro.mp4`,
                )
              }
            />
            <VideoCard
              product="quartz"
              headline="[2] QUARTZ audio modules overview"
              title="QUARTZ AUDIO MODULES"
              key="video-02"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/quartz-web/videos/Quartz_Video_02_Audio_Modules.mp4`,
                )
              }
            />
          </VideoBlock>

          <VideoBlock key="video-3-4">
            <VideoCard
              product="quartz"
              headline="[3] QUARTZ modulation"
              title="WORKING WITH QUARTZ MODULATIONS"
              key="video-03"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/quartz-web/videos/Quartz_Video_03_Modulation.mp4`,
                )
              }
            />
            <VideoCard
              product="quartz"
              headline="[4] LINE EDITOR"
              title="WORKING WITH THE LINE EDITOR"
              key="video-04"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/quartz-web/videos/Quartz_Video_04_Line_Editor.mp4`,
                )
              }
            />
          </VideoBlock>
        </Block>
      </Section>

      <Section key="cta-buttons-2" background="none">
        <Block padding="2rem 1rem 4rem 1rem">
          <CTABUttons />
        </Block>
      </Section>

      <Section key="compatibility-info" background="none">
        <Block padding="2rem 1rem 4rem 1rem">
          <CompatibilityInfo product="QUARTZ" />
        </Block>
      </Section>

      <Section key="download-and-tech" color="white">
        <ProductFooter key="quartz-footer" downloads={quartzDownloads} />
      </Section>
    </>
  );
};

export default Quartz;
