import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import BuyNowButton from "../shared/BuyNowButton";
import { AAButton } from "../StyledElements";
import { pathToS3Bucket } from "../../config";

import {
  Section,
  Headline,
  Block,
  ButtonRow,
  AAImage,
} from "../ResponsiveElements";

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding || "0"};
`;

export const ImageBlock = styled.div`
  padding: ${(props) => props.padding || "1rem 1.0rem"};
  width: 100%;
  max-width: 80rem;
  display: flex;
  @media (max-width: 80rem) {
    flex-direction: column;
  }
  justify-content: center;
  align-items: ${(props) => props.alignItems || "center"};
  /* padding: 2rem; */
`;

const EffectBundle = () => {
  const navigate = useNavigate();

  return (
    <>
      <Section key="section-headline" color="white">
        <Block key="headline">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="2rem 0">
            EFFECT BUNDLE
          </Headline>
          <Headline padding="0 0 2rem 0">
            OBELISK and QUARTZ in a bundle
          </Headline>
          <ButtonRow>
            <BuyNowButton  width="14rem" title="buy 119 € | 129 $" />
          </ButtonRow>
        </Block>

        <ImageBlock key="image">
          <AAImage
            key="obelisk-image"
            maxWidth="20rem"
            onClick={() => navigate("/obelisk")}
            src={`${pathToS3Bucket}/obelisk-web/Obelisk_gui1.png`}
            alt="quartz"
          />
          <AAImage
            key="quartz-image"
            maxWidth="30rem"
            onClick={() => navigate("/quartz")}
            src={`${pathToS3Bucket}/quartz-web/Quartz_gui1.png`}
            alt="quartz"
          />
        </ImageBlock>
      </Section>

      <Section key="headline" background="none">
        <Block key="info-1" padding="3rem 0 0 0">
          <TextBlock key="info-text">
            <div>The Effect Bundle contains OBELISK and QUARTZ.</div>
            <div>You save 39 EUR | 49 USD compared to buying both separately .</div>
          </TextBlock>
        </Block>
        <Block key="info-2" padding="0rem 0 8rem 0">
          <Headline
            key="headline"
            fontSize="1.5rem"
            fontWeight="bold"
            padding="4rem 0 1rem 0"
          >
            You own QUARTZ or OBELISK ?
          </Headline>
          <TextBlock key="info-text" padding="0 0 3rem 0">
            <div>
              Upgrade to the EFFECT BUNDLE and get the other one (OBELISK or
              QUARTZ) at an unbeatable price!
            </div>
          </TextBlock>

          <AAButton key="button" variant="dark" onClick={() => navigate("/shop")}>
            check for upgrade
          </AAButton>
        </Block>
      </Section>
    </>
  );
};

export default EffectBundle;
