import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";

import config, { STAGE, pathToS3Bucket } from "./config";
import { initSentry } from "./libs/errorLib";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store/store";

if (STAGE !== "prod") {
  console.log(
    JSON.stringify(
      {
        STAGE,
        "process.env": process.env,
        config,
        pathToS3Bucket,
      },
      null,
      2,
    ),
  );
}

initSentry();

const awsconfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "aa-api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          const Authorization = `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`;
          return {
            Authorization,
          };
        },
      },
    ],
  },
};

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
