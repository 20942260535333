import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import _ from "lodash";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    paymentInProgress: false,
    stripeInProgress: false,
    activeDownloads: [],
  },
  reducers: {
    setPaymentInProgress: (state, action) => {
      state.paymentInProgress = action.payload;
    },
    setStripeInProgress: (state, action) => {
      state.stripeInProgress = action.payload;
    },
    setDownloadIsActive: (state, action) => {
      state.activeDownloads = [...state.activeDownloads, action.payload];
    },
    setDownloadIsDone: (state, action) => {
      state.activeDownloads = _.without(
        [...state.activeDownloads],
        action.payload,
      );
    },
  },
});

export const currentDownloads = createSelector(
  (state) => state.app.activeDownloads,
  (activeDownloads) => {
    return activeDownloads;
  },
);

export const {
  setPaymentInProgress,
  setStripeInProgress,
  setDownloadIsActive,
  setDownloadIsDone,
} = appSlice.actions;

export default appSlice.reducer;
