import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  ShopAmount,
  IconButton,
  ProductName,
  UpdateLabel,
  UpgradeLabel,
  Badge,
} from "../StyledElements";

import {
  // incrementProduct,
  // decrementProduct,
  removeProduct,
} from "../../store/reducers/cart/cart-reducer";

const CartProductContainer = styled.div`
  padding: 10px 0;
  border: 1px solid gray;
  border-width: 0 0 1px 0;
`;

const CartButtons = styled.div`
  padding: 0 0 1rem 0;
  flex: shrink;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  > * {
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }
`;

const CartButtonsRow = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2rem;
`;

const CartProduct = (props) => {
  const { cart, product, title } = props;
  const dispatch = useDispatch();

  const { currency } = cart;
  const { count, price, isUpdatePrice, isUpgradePrice, badge } = cart[product];

  if (count === 0) return null;

  return (
    <CartProductContainer>
      <Row>
        <ProductName fontSize="1.1rem">{`${count} x ${title === "PLAY_DFAM" ? "PlayDFAM" : title}`}</ProductName>
        {false && isUpdatePrice && <UpdateLabel />}
        {false && isUpgradePrice && <UpgradeLabel />}
        {badge && <Badge>{badge}</Badge>}

        <ShopAmount>{`${(price * 0.01).toFixed(2)} ${currency}`}</ShopAmount>
      </Row>

      <CartButtonsRow>
        <CartButtons>
          {/* <IconButton
            variant="secondary"
            className="material-icons"
            onClick={() => dispatch(incrementProduct({ product }))}
          >
            {"add"}
          </IconButton>
          <IconButton
            variant="secondary"
            className="material-icons"
            onClick={() => dispatch(decrementProduct({ product }))}
          >
            {"remove"}
          </IconButton> */}
          <IconButton
            variant="secondary"
            className="material-icons"
            onClick={() => dispatch(removeProduct({ product }))}
          >
            {"delete"}
          </IconButton>
        </CartButtons>
      </CartButtonsRow>
    </CartProductContainer>
  );
};

export default CartProduct;
