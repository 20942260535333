import React, { useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Spinner from "../shared/Spinner";
import EmailChangeForm from "../authentication/EmailChangeForm";
import EmailVerifyForm from "../authentication/EmailVerifyForm";
import Icon from "../shared/Icon";

import { updateUser } from "../../store/reducers/user/user-reducer";

import { FormInput, LabelAndError, AAButton, Label } from "../StyledElements";

import { world, europe, getCountryDetails } from "../../countries";
import CheckBox from "../shared/CheckBox";

const Row = styled.div`
  display: flex;
  @media (max-width: 35rem) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  flex: 1;
  padding: 0 1rem 0 0;
`;

const ButtonContainer = styled.div`
  padding: 4rem 0;
  display: flex;
  justify-content: space-between;
`;

const FormColumn = styled.div`
  font-family: "Roboto Mono", sans-serif;
  max-width: 800px;
  width: 100%;
  padding: 0 0 5rem 1rem;
`;

const EmailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 2rem 0;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 0 0 2rem 0;
  align-items: center;
  > * {
    margin-right: 1rem;
  }
`;

const Profile = (props) => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [isUpdatingNewsletter, setIsUpdatingNewsletter] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const {
    company = "",
    firstName = "",
    lastName = "",
    street = "",
    streetDetails = "",
    postalCode = "",
    city = "",
    state = "",
    country = "",
    countryCode = "",
    vatNumber = "",
    email,
    emailVerified,
    wantsNewsletter,
  } = profile || {};

  const options = world.map((country) => {
    const { name: label, alpha2: value } = country || {};
    return { value, label };
  });

  const initialValues = {
    company,
    firstName,
    lastName,
    street,
    streetDetails,
    postalCode,
    city,
    state,
    country,
    countryCode,
    vatNumber,
  };

  const handleNewsletterCheckbox = async () => {
    setIsUpdatingNewsletter(true);
    await dispatch(updateUser({ wantsNewsletter: !wantsNewsletter }));
    setIsUpdatingNewsletter(false);
  };

  const handleSubmit = async (values, params) => {
    const { setSubmitting, resetForm } = params;
    await dispatch(updateUser(values));
    resetForm({ values });
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};
    if (values.vatNumber) {
      // vatNumber check (sehr einfach!)
      // https://de.wikipedia.org/wiki/Umsatzsteuer-Identifikationsnummer
      // z. Bsp. DE181933141
      const code = values.vatNumber.substring(0, 2).toLowerCase();
      const isValid = europe.includes(code) && values.vatNumber.length > 7;
      if (!isValid) {
        errors.vatNumber = "this vatNumber is not valid";
      }
    }
    return errors;
  };

  return (
    <FormColumn>
      <EmailRow key="email-row">
        <Icon name="alternate_email" fontSize="1.8rem" width="auto" />
        <Label padding="0 0 0 0.5rem">{email}</Label>
        {emailVerified === true && (
          <Icon
            name="verified"
            fontSize="1.8rem"
            width="auto"
            margin="0 0 0 0.5rem"
            color="verified"
          />
        )}
        {emailVerified === false && (
          <Icon
            name="error"
            fontSize="1.8rem"
            width="auto"
            margin="0 0 0 0.5rem"
            color="error"
          />
        )}
        {emailVerified === false && (
          <Label variant="error" padding="0 0 0 0.5rem">
            {"not verified"}
          </Label>
        )}
      </EmailRow>

      {!isVerifyingEmail && !isEditingEmail && (
        <ButtonRow key="button-row">
          {emailVerified === false && !isVerifyingEmail && !isEditingEmail && (
            <AAButton
              onClick={() => setIsVerifyingEmail(true)}
              variant="dark"
              width="8rem"
              minHeight="2rem"
            >
              {"verify email"}
            </AAButton>
          )}
          {!isEditingEmail && !isVerifyingEmail && (
            <AAButton
              onClick={() => setIsEditingEmail(true)}
              variant="outline"
              width="10rem"
              minHeight="2rem"
            >
              {"change email"}
            </AAButton>
          )}
        </ButtonRow>
      )}
      {isEditingEmail && !isVerifyingEmail && (
        <EmailChangeForm
          profile={profile}
          onCancel={() => setIsEditingEmail(false)}
          onAfterEmailChanged={() => {
            setIsEditingEmail(false);
            setIsVerifyingEmail(true);
          }}
        />
      )}
      {isVerifyingEmail && (
        <EmailVerifyForm
          onCancel={() => setIsVerifyingEmail(false)}
          onFinished={() => setIsVerifyingEmail(false)}
        />
      )}
      <Row>
        <CheckBox
          isChecked={wantsNewsletter}
          onClick={handleNewsletterCheckbox}
          isUpdating={isUpdatingNewsletter}
        />
      </Row>

      {!isEditingEmail && !isVerifyingEmail && (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isValidating,
            dirty,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => {
            const customSelectStyle = {
              control: (provided, state) => ({
                ...provided,
                borderColor:
                  values.country !== initialValues.country
                    ? "orange"
                    : "lightgray",
              }),
            };
            return (
              <>
                <Row>
                  <Col>
                    <LabelAndError label={"company"} error={errors.company} />
                    <FormInput
                      id="company"
                      name="company"
                      type="text"
                      isTouched={values.company !== initialValues.company}
                      onChange={handleChange}
                      value={values.company}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError
                      label={"first name"}
                      error={errors.firstName}
                    />
                    <FormInput
                      id="firstName"
                      name="firstName"
                      type="text"
                      isTouched={values.firstName !== initialValues.firstName}
                      onChange={handleChange}
                      value={values.firstName}
                      className="data-hj-allow"
                    />
                  </Col>

                  <Col>
                    <LabelAndError
                      label={"last name"}
                      error={errors.lastName}
                    />
                    <FormInput
                      id="lastName"
                      name="lastName"
                      type="text"
                      isTouched={values.lastName !== initialValues.lastName}
                      onChange={handleChange}
                      value={values.lastName}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError label={"street"} error={errors.street} />
                    <FormInput
                      id="street"
                      name="street"
                      type="text"
                      isTouched={values.street !== initialValues.street}
                      onChange={handleChange}
                      value={values.street}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError
                      label={"additional infos"}
                      error={errors.streetDetails}
                    />
                    <FormInput
                      id="streetDetails"
                      name="streetDetails"
                      type="text"
                      isTouched={
                        values.streetDetails !== initialValues.streetDetails
                      }
                      onChange={handleChange}
                      value={values.streetDetails}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError
                      label={"post code"}
                      error={errors.postalCode}
                    />
                    <FormInput
                      id="postalCode"
                      name="postalCode"
                      type="text"
                      isTouched={values.postalCode !== initialValues.postalCode}
                      onChange={handleChange}
                      value={values.postalCode}
                      className="data-hj-allow"
                    />
                  </Col>

                  <Col>
                    <LabelAndError label={"city"} error={errors.city} />
                    <FormInput
                      id="city"
                      name="city"
                      type="text"
                      isTouched={values.city !== initialValues.city}
                      onChange={handleChange}
                      value={values.city}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError label={"state"} error={errors.state} />
                    <FormInput
                      id="state"
                      name="state"
                      type="text"
                      isTouched={values.state !== initialValues.state}
                      onChange={handleChange}
                      value={values.state}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError
                      label={"vat number"}
                      error={errors.vatNumber}
                    />
                    <FormInput
                      id="vat"
                      name="vatNumber"
                      type="text"
                      isTouched={values.vatNumber !== initialValues.vatNumber}
                      onChange={handleChange}
                      value={values.vatNumber}
                      className="data-hj-allow"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <LabelAndError
                      label={"country"}
                      error={
                        !values.country ? "please select your country" : ""
                      }
                    />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={customSelectStyle}
                      defaultValue={getCountryDetails(values.countryCode)}
                      isSearchable
                      name="country"
                      options={options}
                      onChange={({ value, label }) => {
                        setFieldValue("countryCode", value, true);
                        setFieldValue("country", label, true);
                      }}
                    />
                  </Col>
                </Row>

                {dirty && (
                  <ButtonContainer key="buttons">
                    <AAButton
                      key="save"
                      onClick={isSubmitting ? () => {} : handleSubmit}
                      variant="success"
                      width="8rem"
                      disabled={Object.keys(errors).length > 0}
                    >
                      {isSubmitting && <Spinner />}
                      {!isSubmitting && <div>save</div>}
                    </AAButton>
                    <AAButton
                      key="cancel"
                      onClick={() => {
                        if (!isSubmitting) {
                          resetForm();
                        }
                      }}
                      variant="dark"
                      width="8rem"
                    >
                      cancel
                    </AAButton>
                  </ButtonContainer>
                )}
              </>
            );
          }}
        </Formik>
      )}
    </FormColumn>
  );
};

export default Profile;
