import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { fetchUser } from "../../store/reducers/user/user-reducer";

import Spinner from "../shared/Spinner";

import {
  FormInput,
  Label,
  LabelAndError,
  AAButton,
  SignContainer,
  SignLink,
  SignButtonRow,
  GlobalError,
} from "../StyledElements";

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20rem;
  padding: 0 5rem 0 0;
`;

const LoginForm = (props) => {

  const { userData: isLoading } = useSelector((state) => state.user.isLoading);

  const { email, afterLogin, status } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: email || "",
    password: "",
  };

  const message =
    status === "confirmed"
      ? "your registration is confirmed, you can login now"
      : "";

  const onSubmit = async (values, params) => {
    setErrorMessage("");
    try {
      const { email, password } = values;
      await Auth.signIn(email, password);
      dispatch(fetchUser());
      if (afterLogin) {
        afterLogin();
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  return (
    <>
      <GlobalError>{errorMessage || message}</GlobalError>
      <SignContainer>
        {isLoading && (
          <SpinnerContainer>
            <Label padding="1rem">{"loading"}</Label>
            <Spinner fontSize="2.5rem" />
          </SpinnerContainer>
        )}
        {!isLoading && (
          <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <>
                  <LabelAndError label={"email"} />
                  <FormInput
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    className="data-hj-allow"
                  />
                  <LabelAndError label={"password"} />
                  <FormInput
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <SignButtonRow>
                    <AAButton
                      onClick={handleSubmit}
                      variant="secondary"
                      width="8rem"
                    >
                      {!isSubmitting ? "login" : <Spinner />}
                    </AAButton>
                  </SignButtonRow>
                </>
              )}
            </Formik>

            <SignLink key="no-account" padding="3rem 0 0 0">
              <span>no account?</span>
              <div onClick={() => navigate("/register")}>register</div>
            </SignLink>
            <SignLink key="forgot-password" padding="2rem 0">
              <span>forgot password?</span>
              <div onClick={() => navigate("/reset-password")}>
                reset password
              </div>
            </SignLink>
          </>
        )}
      </SignContainer>
    </>
  );
};

export default LoginForm;
