import React from "react";
import styled from "styled-components";

import logo from "../../aa-logo.svg";
import { TextBox } from "../StyledElements";

const Container = styled.div`
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackSoonPage = (props) => {
  return (
    <Container>
      <img src={logo} className="App-logo" alt="logo" width="90" />
      <TextBox padding="3rem 0 0 0">we will be back soon</TextBox>
    </Container>
  );
};

export default BackSoonPage;
