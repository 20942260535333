import React, { useState } from "react";

import Tag from "../shared/Tag";
import PlugInFeature from "../shared/PluginFeature";

import BuyNowButton from "../shared/BuyNowButton";
import VideoCard from "../shared/VideoCard";
import VideoPlayerModal from "../shared/VideoPlayerModal";
import DownloadDemoButton from "../shared/DownloadDemoButton";
import CompatibilityInfo from "../shared/CompatibilityInfo";

import ProductFooter from "./ProductFooter";
import { downloadIds } from "../../download-ids";
import { pathToS3Bucket } from "../../config";

import {
  Section,
  Headline,
  Block,
  FeatureRow,
  ButtonRow,
  AAImage,
  VideoBlock,
} from "../ResponsiveElements";

const obeliskFeatures = [
  {
    title: "SPECTRAL DELAY",
    description: `
    OBELISK provides an individual delay for every frequency band. OBELISK therefore contains 512 conventional delays with individual delay times of up to 3 seconds. Each delay has its own feedback.
  `,
    imageUrl: `${pathToS3Bucket}/obelisk-web/obelisk_feature_delay_feedback.png`,
    alt: "Spectral Delay",
    maxHeight: "20rem",
    imageRight: false,
  },
  {
    title: "SPECTRAL FILTER",
    description: `
    In OBELISK every frequency band has its own volume regulation. By controlling the volume of all the frequency bands, the effect signal can almost be arbitrarily filtered. Furthermore, the intensity of the filter can be globally controlled for all the frequency bands. As a result of the course of the FILTER LINE, extremely accurate and also ultimate filter settings can be achieved.
    `,
    imageUrl: `${pathToS3Bucket}/obelisk-web/obelisk_feature_filter.png`,
    alt: "Spectral Filter",
    maxHeight: "20rem",
    imageRight: true,
  },
  {
    title: "SPECTRAL GATE",
    description: `
    In OBELISK every frequency band has its own noise gate with an individual threshold. As a result, OBELISK provides a total of 512 conventional noise gates. The thresholds of the individual frequency bands ensue as a result of the course of the GATE LINE. If the level of a frequency band is above the GATE LINE, it will be allowed through but if the level is under the GATE LINE it will become mute. With the aid of the REAL-TIME ANALYSER SPECTRUM, the GATE LINE can be perfectly adapted to the audio signal.
    `,
    imageUrl: `${pathToS3Bucket}/obelisk-web/obelisk_feature_gate.png`,
    alt: "Spectral Gate",
    maxHeight: "20rem",
    imageRight: false,
  },
  {
    title: "2-DIMENSIONAL MODULATION",
    description: `
    With its 2-dimensional LFOs, OBELISK has completely innovative modulation possibilities. Each of these 3 LFOs can control the movement of up to 5 EFFECT POINTS almost arbitrarily. The course of the EFFECT LINES can therefore be influenced in an extremely creative and often unpredictable fashion. All LFOs can be synchronized to the song tempo and thus can be used for the modulation in a very musical manner.
    `,
    imageUrl: `${pathToS3Bucket}/obelisk-web/obelisk_feature_LFOs.png`,
    alt: "2-D Modulation",
    maxHeight: "20rem",
    imageRight: true,
  },
  {
    title: "HIGH QUALITY SPECTRAL ANALYSIS",
    description: `
    OBELISK has a high quality spectral analysis and synthesis. The audio signal can be split into 256 or 512 individual frequency bands that can be independently processed with effects. OBELISK ensures an excellent audio quality and requires very little computing power as a result of its highly optimized vector algorithms.
    `,
    imageUrl: `${pathToS3Bucket}/obelisk-web/obelisk_feature_analysis.png`,
    alt: "High Quality Spectral Analysis",
    maxHeight: "12rem",
    imageRight: false,
  },
];

const obeliskDownloads = [
  {
    icon: "fa-apple",
    // title: "OBELISK demo (mac)",
    title: `OBELISK Demo ${downloadIds.obelisk.currentVersion} (MAC)`,
    downloadId: downloadIds.obelisk.demoInstaller.mac,
  },
  {
    icon: "fa-windows",
    title: `OBELISK Demo ${downloadIds.obelisk.currentVersion} (WIN)`,
    downloadId: downloadIds.obelisk.demoInstaller.win,
  },
  {
    icon: "fa-book",
    title: "manual (english)",
    downloadId: downloadIds.obelisk.manual.english,
  },
  {
    icon: "fa-book",
    title: "manual (german)",
    downloadId: downloadIds.obelisk.manual.german,
  },
];

const CTABUttons = () => (
  <ButtonRow>
    <BuyNowButton key="buy-button" title="buy 79€ | 89$" />
    <DownloadDemoButton
      icon="fa-apple"
      label={`Demo ${downloadIds.obelisk.currentVersion}`}
      downloadId={downloadIds.obelisk.demoInstaller.mac}
    />
    <DownloadDemoButton
      icon="fa-windows"
      label={`Demo ${downloadIds.obelisk.currentVersion}`}
      downloadId={downloadIds.obelisk.demoInstaller.win}
    />
  </ButtonRow>
);

const Obelisk = () => {
  const [videoUrl, setVideoUrl] = useState("");

  const openVideoPlayer = (url) => {
    setVideoUrl(url);
  };
  const closeVideoPlayer = () => {
    setVideoUrl("");
  };

  return (
    <>
      {videoUrl && (
        <VideoPlayerModal url={videoUrl} onClose={closeVideoPlayer} />
      )}

      <Section key="section-hero" color="white">
        <Block key="headline">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="2rem 0">
            OBELISK
          </Headline>
          <Headline padding="0 0 1rem 0">
            award-winning spectral delay and multi-effect plug-in
          </Headline>

          <CTABUttons key="cta-buttons" />
        </Block>

        <Block key="image">
          <AAImage
            key="hero-image"
            maxWidth="40rem"
            src={`${pathToS3Bucket}/obelisk-web/Obelisk_gui1.png`}
            alt="obelisk"
          />
          <FeatureRow key="new-features" padding="2rem 0">
            <Tag name="New" />
            <div>editor scalable up to 200%</div>
            <div>drag-and-drop mod assigmnent</div>
            <div>runs native on Apple Silicon Macs</div>
          </FeatureRow>
        </Block>
      </Section>

      <Section key="obelisk-modules" background="none">
        <Block key="effect-modules">
          <Headline
            key="headline"
            fontSize="1.5rem"
            fontWeight="bold"
            padding="4rem 0 1rem 0"
          >
            OBELISK
          </Headline>
          <Headline
            key="description-1"
            fontSize="1.0rem"
            padding="0 0 1rem 0"
            maxWidth="60rem"
          >
            OBELISK is a spectral multi effect whose sound possibilities and
            graphic user interface raise the area of spectral sound processing
            to a completely new level.
          </Headline>
          <Headline
            key="description-2"
            fontSize="1.0rem"
            padding="0 0 2rem 0"
            maxWidth="60rem"
          >
            OBELISK combines a SPECTRAL DELAY, a SPECTRAL FILTER and a SPECTRAL
            GATE to a multi effect that not only sounds excellent but also
            enables an extremely intuitive and musical operation. With OBELISK
            you can draw your effect and with the aid of graphic lines control a
            multitude of effect parameters in a very simple and descriptive
            manner.
          </Headline>
        </Block>

        <Block key="modules-list">
          {obeliskFeatures.map((feature, i) => (
            <PlugInFeature key={`feature-${i}`} content={feature} />
          ))}
        </Block>
      </Section>

      <Section key="analyser-point-view" background="none">
        <Block key="image">
          <Headline
            key="title"
            fontSize="1.0rem"
            fontWeight="bold"
            padding="0 0 1.5rem 0"
          >
            ANALYSER POINT VIEW
          </Headline>
          <Headline
            key="description-1"
            fontSize="1.0rem"
            padding="0 0 1.5rem 0"
            maxWidth="50rem"
          >
            With OBELISK you control all the parameters via a unique graphic
            user-interface. The ANALYSER POINT VIEW constitutes the central unit
            for processing the SPECTRAL DELAY, SPECTRAL FILTER and SPECTRAL
            GATE. In this respect you control hundreds of effect parameters by
            means of drawing graphic lines, the so-called EFFECT LINES. In spite
            of the abundance of parameters that spectral sound processing
            provides, it is possible to operate extremely intuitively and almost
            musically.
          </Headline>
          <AAImage
            key="image"
            maxWidth="50rem"
            src={`${pathToS3Bucket}/obelisk-web/obelisk_analyser_point_view.png`}
            alt="analyser point view"
          />
          <Headline
            key="description-2"
            fontSize="1.0rem"
            padding="1.5rem 0 4rem 0"
            maxWidth="50rem"
          >
            Each EFFECT LINE consists of up to 10 EFFECT POINTS that determine
            the course of the line. The REAL-TIME ANALYSER SPECTRUM with which
            you can optically follow all the sound changes to the audio signal
            is situated underneath the EFFECT LINES.
          </Headline>
        </Block>
      </Section>

      <Section key="video-headline" background="none">
        <Headline key="title" fontSize="1.5rem" fontWeight="bold" padding="0">
          VIDEOS
        </Headline>
      </Section>

      <Section key="videos" background="none">
        <Block key="main-video" padding="1rem 1rem 4rem 1rem">
          <VideoBlock key="video-1-2">
            <VideoCard
              product="obelisk"
              headline="[1] introducing OBELISK"
              title="QUICK START AND INTRODUCTION"
              key="video-01"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/01_Obelisk_Video_Intro.mp4`,
                )
              }
            />
            <VideoCard
              product="obelisk"
              headline="[2] the SPECTRAL DELAY"
              title="HOW THE SPECTRAL DELAY EFFECT WORKS"
              key="video-02"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/02_Obelisk_Video_Delay.mp4`,
                )
              }
            />
          </VideoBlock>

          <VideoBlock key="video-3-4">
            <VideoCard
              product="obelisk"
              headline="[3] synchronizing the SPECTRAL DELAY"
              title="SPECTRAL DELAY SYNC"
              key="video-03"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/03_Obelisk_Video_Delay_Sync.mp4`,
                )
              }
            />
            <VideoCard
              product="obelisk"
              headline="[4] SPECTRAL DELAY feedback"
              title="SPECTRAL DELAY FEEDBACK"
              key="video-04"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/04_Obelisk_Video_Feedback.mp4`,
                )
              }
            />
          </VideoBlock>

          <VideoBlock key="video-5-6">
            <VideoCard
              product="obelisk"
              headline="[5] SPECTRAL FILTER"
              title="THE SPECTRAL FILTER EXPLAINED"
              key="video-05"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/05_Obelisk_Video_Filter.mp4`,
                )
              }
            />
            <VideoCard
              product="obelisk"
              headline="[6] SPECTRAL GATE"
              title="THE SPECTRAL GATE EXPLAINED"
              key="video-06"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/06_Obelisk_Video_Gate.mp4`,
                )
              }
            />
          </VideoBlock>

          <VideoBlock key="video-7-8">
            <VideoCard
              product="obelisk"
              headline="[7] working with MODULATION"
              title="HOW TO MODULATE THE LINES"
              key="video-07"
              onClick={() =>
                openVideoPlayer(
                  `${pathToS3Bucket}/obelisk-web/videos/07_Obelisk_Video_Modulation.mp4`,
                )
              }
            />
          </VideoBlock>
        </Block>
      </Section>

      <Section key="cta-buttons-2" background="none">
        <Block padding="4rem 1rem">
          <CTABUttons />
        </Block>
      </Section>

      <Section key="compatibility-info" background="none">
        <Block padding="2rem 1rem 4rem 1rem">
          <CompatibilityInfo product="OBELISK" />
        </Block>
      </Section>

      <Section key="download-and-tech" color="white">
        <ProductFooter key="obelisk-footer" downloads={obeliskDownloads} />
      </Section>
    </>
  );
};

export default Obelisk;
