import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Spinner from "../shared/Spinner";

Modal.setAppElement("#paypal-modal-root");

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Mono", sans-serif;
  > * {
    padding: 1rem;
    color: white;
  }
`;

const modalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  content: {
    position: "absolute",
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%",
    border: "0px solid red",
    background: "transparent",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
  },
};

const PaypalModal = (props) => {
  const paymentInProgress = useSelector((state) => state.app.paymentInProgress);

  return (
    <Modal isOpen={paymentInProgress} style={modalStyles}>
      <ModalContainer>
        <Spinner />
        <div>{"we are processing your order"}</div>
        <div>{"please do not close this window"}</div>
      </ModalContainer>
    </Modal>
  );
};

export default PaypalModal;
