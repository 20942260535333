import React, { useState } from "react";
import { API } from "aws-amplify";
import { jsPDF } from "jspdf";
import styled from "styled-components";

import { TextLine, AAButton } from "../StyledElements";
import Icon from "../shared/Icon";

const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Order = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { order } = props;
  const {
    itemId,
    products,
    invoiceNumber,
    currency = "",
    date,
    taxPercent,
    total,
    taxAmount,
    canPrintPdf = false,
  } = order;

  const amountText =
    taxPercent > 0
      ? `${total.toFixed(
          2,
        )} ${currency} incl. ${taxPercent}% tax = ${taxAmount.toFixed(
          2,
        )} ${currency}`
      : `${total.toFixed(2)} ${currency}`;

  const handleDownloadReceipt = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const { invoiceContent } = await API.get(
      "aa-api",
      `/invoice?orderId=${itemId}`,
    );
    // console.log(JSON.stringify({ invoiceContent }, null, 2));

    const doc = new jsPDF();
    const borderLeft = 30;
    const lineHeight = 8;
    let lineNumber = 4;

    doc.setTextColor("#4A4A4A");
    doc.setFont("Courier");

    invoiceContent.forEach(({ fontSize, text }) => {
      doc.setFontSize(fontSize);
      doc.text(text, borderLeft, lineNumber * lineHeight);
      lineNumber += 1;
    });
    doc.save(`${invoiceNumber} artificial audio.pdf`);
    setIsSubmitting(false);
  };

  return (
    <Container>
      <TextLine key="order-number" padding="1rem 0 0 0">
        <div>{`order number ${invoiceNumber}`}</div>
      </TextLine>

      <TextLine key="order-date" padding="0.2rem 0 1rem 0">
        <div>{`${date.toLowerCase()}`}</div>
      </TextLine>

      {products.map((product, i) => {
        const { title, count } = product;
        return (
          <TextLine key={`product-${i}`} padding="0.2rem 2rem">
            {`${count} x ${title === "PLAY_DFAM" ? "PlayDFAM" : title}`}
          </TextLine>
        );
      })}

      <TextLine key="amount" padding="1rem 0 2rem 0">
        {amountText}
      </TextLine>

      {canPrintPdf && (
        <AAButton
          variant="outline"
          onClick={handleDownloadReceipt}
        >
          <Icon
            name={isSubmitting ? "done" : "receipt"}
            width="2.5rem"
            fontSize="25px"
          />
          <span>download receipt</span>
        </AAButton>
      )}
    </Container>
  );
};

export default Order;
