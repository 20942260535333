import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "../pages/Home";
import PlayDfam from "../products/PlayDfam";
import Obelisk from "../products/Obelisk";
import Quartz from "../products/Quartz";
import EffectBundle from "../products/EffectBundle";

import Shop from "../shop/Shop";
import ThankYou from "../shop/ThankYou";

import UnsubscribePage from "../account/UnsubscribePage";

import Login from "../authentication/Login";
import ResetPassword from "../authentication/ResetPassword";
import ResetPasswordConfirm from "../authentication/ResetPasswordConfirm";
import Register from "../authentication/Register";
import Confirm from "../authentication/Confirm";

import Dashboard from "../account/Dashboard";

import Imprint from "../pages/Imprint";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";

import NotFound from "../pages/NotFound";
import BackSoonPage from "../pages/BackSoonPage";

import RequireAuth from "../routes/RequireAuth";

export default function AppRoutes(props) {
  const IS_ONLINE = process.env.REACT_APP_IS_ONLINE === "true";
  const isAuthenticated = useSelector((state) => state.user.isAuth);
  const SHOW_WEBSITE = IS_ONLINE || isAuthenticated;

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={SHOW_WEBSITE ? <Home /> : <BackSoonPage />}
      />

      <Route exact path="/shop" element={SHOW_WEBSITE && <Shop />} />
      {process.env.REACT_APP_SHOW_NEW_PRODUCT === "true" && (
        <>
          <Route
            exact
            path="/playdfam"
            element={SHOW_WEBSITE && <PlayDfam />}
          />
          <Route
            path="/syncdfam"
            element={SHOW_WEBSITE && <Navigate to="/playdfam" />}
          />
        </>
      )}
      <Route exact path="/obelisk" element={SHOW_WEBSITE && <Obelisk />} />
      <Route exact path="/quartz" element={SHOW_WEBSITE && <Quartz />} />
      <Route
        exact
        path="/effectbundle"
        element={SHOW_WEBSITE && <EffectBundle />}
      />

      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/confirm" element={<Confirm />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/new-password" element={<ResetPasswordConfirm />} />

      <Route exact path="/unsubscribe" element={<UnsubscribePage />} />

      <Route exact path="/imprint" element={<Imprint />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/privacy" element={<Privacy />} />

      <Route
        path="/dashboard/*"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

      <Route
        exact
        path="/thanks"
        element={
          <RequireAuth>
            <ThankYou />
          </RequireAuth>
        }
      />

      {/* forward the old public links to home */}
      <Route path="/productmenu/:id" element={<Navigate to="/" />} />
      <Route path="/pluginmenu/:id" element={<Navigate to="/" />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
