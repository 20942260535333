import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Spinner from "../shared/Spinner";

import {
  FormInput,
  LabelAndError,
  AAButton,
  SignContainer,
  SignLink,
  SignButtonRow,
  TextLine,
} from "../StyledElements";

const ConfirmForm = (props) => {
  const { email = "" } = props;
  const [didResendCode, setDidResendCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    email,
    code: "",
  };

  const message = didResendCode
    ? "code was sent again"
    : "please enter the confirmation code that we sent you by email";

  const onSubmit = async (values, params) => {
    setErrorMessage("");
    setDidResendCode(false);
    try {
      const { email, code } = values;
      await Auth.confirmSignUp(email, code);
      navigate(`/login?email=${email}`);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const resendConfirmationCode = async (email) => {
    try {
      await Auth.resendSignUp(email);
      setDidResendCode(true);
      setErrorMessage("");
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("invalid email address").required("required"),
    code: Yup.string().matches(/^\d{6}$/, "6 digits"),
  });

  return (
    <>
      <SignContainer>
        <TextLine
          padding="0 0 2rem 0"
          variant={errorMessage ? "error" : "black"}
        >
          {errorMessage || message}
        </TextLine>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <LabelAndError label={"email"} />
              <FormInput
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                className="data-hj-allow"
              />
              <LabelAndError label={"code"} error={errors.code}/>
              <FormInput
                name="code"
                type="text"
                onChange={handleChange}
                value={values.code}
              />
              <SignButtonRow>
                <AAButton
                  onClick={handleSubmit}
                  variant="secondary"
                  width="8rem"
                >
                  {!isSubmitting ? "confirm" : <Spinner />}
                </AAButton>
              </SignButtonRow>
              <SignLink padding="2rem 0 0 0">
                <span>no code received?</span>
                <div onClick={() => resendConfirmationCode(values.email)}>
                  resend code
                </div>
              </SignLink>
            </>
          )}
        </Formik>
      </SignContainer>
    </>
  );
};

export default ConfirmForm;
