import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import Icon from "./Icon";

const Container = styled.div`
  max-width: 60rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const CloseButtonRow = styled.div`
  padding: 0 0 1rem 0;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  > * {
    padding: 1rem;
  }
`;

const myReactPlayer = ({ className, url }) => (
  <ReactPlayer
    className={className}
    url={url}
    playing
    width="100%"
    height="auto"
    controls={true}
  />
);

const Player = styled(myReactPlayer)`
  /* border: 1px solid red; */
  top: 0;
  left: 0;
`;

const VideoPlayer = (props) => {
  const { onClose, url } = props;
  return (
    <Container>
      <CloseButtonRow key="close-button">
        <CloseButton onClick={onClose}>
          <div>close</div>
          <Icon color="white" name="close" fontSize="1.8rem" width="2rem" />
        </CloseButton>
      </CloseButtonRow>

      <Player key="player" url={url} onClose={onClose} />

    </Container>
  );
};

export default VideoPlayer;
