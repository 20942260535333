import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useSelector, useDispatch } from "react-redux";
import { myCart } from "../../store/reducers/cart/cart-reducer";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../shared/Spinner";

import { setPaymentInProgress } from "../../store/reducers/app/app-reducer";
import { cartDetails, clearCart } from "../../store/reducers/cart/cart-reducer";
import { fetchUser } from "../../store/reducers/user/user-reducer";
import { generateInvoiceNumber } from "../../helpers/helpers";

import { PAYPAL_CLIENT_ID } from "../../config";

export const Container = styled.div`
  padding: 3rem 0 0 0;
  width: 100%;
  display: ${(props) => props.display || "flex"};
`;

const LoadingIndicatorBox = styled.div`
  /* border: 1px solid gray; */
  border-radius: 0.4rem;
  padding: 0 1rem;
  display: flex;
  height: 2.2rem;
  align-items: center;
  justify-content: center;
  background: rgb(254, 191, 73);
  color: white;
  > * {
    margin-right: 1rem;
  }
`;

const LoadingIndicator = () => {
  return (
    <LoadingIndicatorBox>
      <Spinner fontSize="1.5rem" variant="secondary"/>
      <span>connecting to paypal</span>
    </LoadingIndicatorBox>
  );
};

const Paypal = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(myCart);
  const cartDetailsForOrder = useSelector(cartDetails);

  const invoiceNumber = generateInvoiceNumber();

  // console.log('PAYPAL_CLIENT_ID:', PAYPAL_CLIENT_ID);
  // console.log(JSON.stringify({ cart, cartDetailsForOrder }, null, 2));

  const createOrder = (data, actions) => {
    // https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
    const orderData = {
      intent: "capture",
      purchase_units: [
        {
          invoice_id: invoiceNumber,
          amount: {
            currency_code: cart.currency,
            value: cartDetailsForOrder.total,
          },
        },
      ],
    };
    if (process.env.REACT_APP_STAGE === "dev")
      console.log(JSON.stringify({ orderData }, null, 2));
    return actions.order.create(orderData);
  };

  const onApprove = async (data, actions) => {
    dispatch(setPaymentInProgress(true));
    setIsVisible(false); // siehe: https://github.com/bebu259/aa-api/issues/5
    const { orderID, payerID, paymentID = "unknown" } = data;

    const orderParams = {
      // ...cartDetails(),
      ...cartDetailsForOrder,
      paymentMethod: "PAYPAL",
      orderID,
      payerID,
      paymentID,
      invoiceNumber,
    };

    if (process.env.REACT_APP_STAGE === "dev")
      console.log(JSON.stringify({ orderParams }, null, 2));

    // the paypal payment is done when capture() does not throw an error

    return actions.order
      .capture()
      .then(async (captureDetails) => {
        await API.post("aa-api", `/order`, { body: orderParams });
        dispatch(setPaymentInProgress(false));
        setIsVisible(true);
        dispatch(clearCart());
        dispatch(fetchUser());
        navigate("/thanks");
      })
      .catch((error) => {
        console.log(error);
        dispatch(setPaymentInProgress(false));
        setIsVisible(true);
      });
  };

  const style = {
    layout: "horizontal",
    color: "gold",
    shape: "rect",
    label: "buynow",
    size: "large",
    height: 35,
  };

  const options = {
    "client-id": PAYPAL_CLIENT_ID,
    intent: "capture",
    currency: cart.currency,
  };

  const onButtonReady = () => {
    setIsReady(true);
  };

  return (
    <Container display={isVisible ? "flex" : "none"}>
      {!isReady && <LoadingIndicator />}
      <PayPalButton
        currency={cart.currency}
        style={style}
        options={options}
        onButtonReady={onButtonReady}
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </Container>
  );
};

export default Paypal;
