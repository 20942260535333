import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Spinner from "../shared/Spinner";

import {
  FormInput,
  LabelAndError,
  AAButton,
  SignContainer,
  SignLink,
  SignButtonRow,
  GlobalError,
} from "../StyledElements";

const RegisterForm = (props) => {
  const { afterRegister } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    passwordConfirm: "",
  };

  const onSubmit = async (values, params) => {
    setErrorMessage("");
    try {
      // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-up
      const { email, password } = values;
      await Auth.signUp(email, password);
      if (afterRegister) {
        afterRegister(email);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("invalid email address").required("required"),
    password: Yup.string()
      .min(8, "must be 8 characters or more")
      .required("required"),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "does not match",
    ),
  });

  return (
    <>
      <GlobalError>{errorMessage}</GlobalError>
      <SignContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <LabelAndError label={"email"} error={errors.email} />
              <FormInput
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                className="data-hj-allow"
              />
              <LabelAndError
                label={
                  !errors.password ? "password (min 8 characters)" : "password"
                }
                error={errors.password}
              />
              <FormInput
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
              />
              <LabelAndError
                label={"confirm password"}
                error={errors.passwordConfirm}
              />
              <FormInput
                name="passwordConfirm"
                type="password"
                onChange={handleChange}
                value={values.passwordConfirm}
              />
              <SignButtonRow>
                <AAButton
                  onClick={handleSubmit}
                  variant="secondary"
                  width="8rem"
                >
                  {!isSubmitting ? "register" : <Spinner />}
                </AAButton>
              </SignButtonRow>
              <SignLink key="have-account" padding="3rem 0 0 0">
                <span>you have an account?</span>
                <div onClick={() => navigate("/login")}>login</div>
              </SignLink>
            </>
          )}
        </Formik>
      </SignContainer>
    </>
  );
};

export default RegisterForm;
