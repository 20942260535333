import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Section, Block } from "../ResponsiveElements";
import { AAButton } from "../StyledElements";
import Icon from "../shared/Icon";
import Licenses from "./Licenses";
import OrderList from "./OrderList";
import Profile from "./Profile";

const TabContainer = styled.div`
  border: 1px solid red;
  border-width: 0 0 4px 0;
  padding-bottom: 5px;
  border-color: ${(props) => (props.isActive ? "gray" : "transparent")};
  /* border: 1px solid red; */
`;

const TabBar = styled.div`
  border: 1px solid gray;
  border-width: 0 0 1px 0;
  margin-bottom: 4rem;
  padding: 20px 0 0 0;
  display: flex;
  > * {
    /* margin-right: 1rem; */
  }
`;

const Dashboard = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const Tab = (props) => (
    <TabContainer isActive={pathname === `/dashboard/${props.url}`}>
      <AAButton
        variant="simple"
        // padding="0.4rem 1rem "
        margin="0 1rem 0 0"
        onClick={() => navigate(props.url)}
        hideTextOnSmallDevice
      >
        <Icon name={props.icon} />
        <span>{props.title}</span>
      </AAButton>
    </TabContainer>
  );

  return (
    <Section background="white">
      <Block alignItems="flex-start" padding="1rem 1rem 6rem 1rem">
        <TabBar key="tab-bar">
          <Tab title="account" icon="portrait" url={"account"} />
          <Tab
            title="licenses"
            icon="settings_input_component"
            url={"licenses"}
          />
          <Tab title="orders" icon="list_alt" url={"orders"} />
        </TabBar>

        <Routes key="routes">
          <Route path="account" element={<Profile />} />
          <Route path="licenses" element={<Licenses />} />
          <Route path="orders" element={<OrderList />} />
        </Routes>
      </Block>
    </Section>
  );
};

export default Dashboard;
