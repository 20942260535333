import React from "react";
import { useDispatch } from "react-redux";

import { AAButton, Icon } from "../StyledElements";
import { incrementProduct } from "../../store/reducers/cart/cart-reducer";

const AddToCardButton = ({ product } ) => {
  const dispatch = useDispatch();

  return (
    <AAButton
      width={"auto"}
      variant="secondary"
      onClick={() => dispatch(incrementProduct({ product }))}
    >
      <Icon className="material-icons">add_shopping_cart</Icon>
      <span>add</span>
    </AAButton>
  );
};

export default AddToCardButton;
