import React from "react";
import styled from "styled-components";
import { textColor } from '../StyledElements';

export const Container = styled.div`
  display: flex;
  margin: ${(props) => props.margin || "0"};
  justify-content: center;
  align-items: center;
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }
  animation: spinner 1s linear infinite;
  color: ${(props) => textColor[props.variant] || textColor.primary};
  > * {
    /* font-size: 36px; */
    font-size: ${(props) => props.fontSize || "1.8rem"};
  }
`;

const Spinner = (props) => {
  const { fontSize, variant, margin } = props;
  return (
    <Container fontSize={fontSize} variant={variant} margin={margin}>
      <i className="material-icons">refresh</i>
    </Container>
  );
};

export default Spinner;
