import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import {
  ProductName,
  UpdateLabel,
  UpgradeLabel,
  AAButton,
} from "../StyledElements";

import ProductPrice from "./ProductPrice";
import AddToCardButton from "./AddToCardButton";

import { userPrices } from "../../store/reducers/prices/prices-reducer";

const ProductList = styled.div`
  flex: 3;
  max-width: 50rem;
  padding: 0 0 3rem 0;
  > * {
    border: 1px solid lightgray;
    border-width: 0 0 2px 0;
    &:nth-child(1) {
      border-width: 2px 0;
      @media (max-width: 950px) {
        border-width: 0 0 2px 0;
      }
    }
  }
`;

const Product = styled.div`
  padding: 2rem 1rem 1rem 0;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  @media (max-width: 35rem) {
    align-items: flex-start;
  }
`;

const Message = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  color: gray;
  font-size: 0.9rem;
  flex: 1;
`;

const ProductTitle = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex: 1;
  align-items: center;
  > * {
    &:nth-child(1) {
      font-weight: bold;
      font-size: 1.2rem;
    }
    &:nth-child(2) {
    }
  }
  @media (max-width: 35rem) {
    flex-direction: column;
    align-items: flex-start;
    > * {
      &:nth-child(2) {
        margin: 0 0 0 1rem;
      }
    }
  }
`;

const Products = () => {
  const navigate = useNavigate();

  const prices = useSelector(userPrices);
  const { obelisk, quartz, effectbundle } = prices;

  const entries = [];

  if (process.env.REACT_APP_SHOW_NEW_PRODUCT === "true") {
    const { play_dfam } = prices;
    entries.push({
      ...play_dfam,
      product: "play_dfam",
      title: "PLAY_DFAM",
      url: "/playdfam",
    });
  }

  entries.push(
    { ...obelisk, product: "obelisk", title: "OBELISK", url: "/obelisk" },
    { ...quartz, product: "quartz", title: "QUARTZ", url: "/quartz" },
    {
      ...effectbundle,
      product: "effectbundle",
      title: "EFFECT BUNDLE",
      url: "/effectbundle",
    },
  );

  return (
    <ProductList>
      {_.map(entries, (entry) => (
        <Product key={entry.title}>
          <Row>
            <ProductTitle key="titel">
              <AAButton
                variant="simple"
                onClick={() => {
                  navigate(entry.url);
                }}
              >
                <ProductName>
                  {entry.title === "PLAY_DFAM" ? "PlayDFAM" : entry.title}
                </ProductName>
              </AAButton>
              {entry.isUpgradePrice && <UpgradeLabel />}
              {entry.isUpdatePrice && <UpdateLabel />}
            </ProductTitle>
            <ProductPrice priceDetails={entry} />
            <AddToCardButton product={entry.product} />
          </Row>
          <Message>{entry.message}</Message>
        </Product>
      ))}
    </ProductList>
  );
};

export default Products;
