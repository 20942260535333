import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { updateUserProfile } from "../../store/reducers/user/user-reducer";

import Spinner from "../shared/Spinner";

import {
  FormInput,
  AAButton,
  SignLink,
  SignButtonRow,
  TextLine,
  LabelAndError,
} from "../StyledElements";

const Container = styled.div`
  padding: 1rem 0 2rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormBox = styled.div`
  padding: 0 0 2rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const EmailVerifyForm = (props) => {
  const dispatch = useDispatch();
  const [didResendCode, setDidResendCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { onFinished } = props;

  const initialValues = {
    code: "",
  };

  const message = didResendCode
    ? "code was sent again"
    : "please enter the confirmation code that we sent you by email";

  const onSubmit = async (values, params) => {
    setErrorMessage("");
    setDidResendCode(false);
    try {
      const { code } = values;
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      // console.log(`Auth.verifyCurrentUserAttributeSubmit(${code}) OK`);
      dispatch(updateUserProfile({ emailVerified: true }));
      onFinished();
    } catch (error) {
      // console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const resendConfirmationCode = async () => {
    try {
      Auth.verifyCurrentUserAttribute("email");
      setDidResendCode(true);
      setErrorMessage("");
      // console.log(`Auth.verifyCurrentUserAttribute() OK`);
    } catch (error) {
      // console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const validationSchema = Yup.object({
    code: Yup.string().matches(/^\d{6}$/, "6 digits"),
  });

  return (
    <Container>
      <TextLine padding="0 0 1rem 0" variant={errorMessage ? "error" : "black"}>
        {errorMessage || message}
      </TextLine>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => {
          const disabled = isSubmitting || !isValid || !dirty;

          return (
            <FormBox>
              <LabelAndError label={"code"} error={errors.code} />
              <FormInput
                name="code"
                type="text"
                onChange={handleChange}
                value={values.code}
                maxWidth="50%"
                placeholder="code"
              />
              <SignButtonRow>
                <AAButton
                  onClick={disabled ? () => {} : handleSubmit}
                  variant={disabled ? "outline" : "secondary"}
                  width="8rem"
                  disabled={disabled}
                >
                  {!isSubmitting ? "confirm" : <Spinner />}
                </AAButton>
              </SignButtonRow>
              <SignLink padding="2rem 0 0 0">
                <span>no code received?</span>
                <div onClick={resendConfirmationCode}>resend code</div>
              </SignLink>
            </FormBox>
          );
        }}
      </Formik>
    </Container>
  );
};

export default EmailVerifyForm;
