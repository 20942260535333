import styled from "styled-components";

import { textColor } from "./StyledElements";

export const AppWrapper = styled.div`
  /* border: 0.1rem solid green; */
  display: flex;
  flex-direction: column;

  padding: 0;

  overflow-x: hidden;
  overflow-y: auto;

  justify-content: center;
  -webkit-overflow-scrolling: touch;
  /* https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/ */

  @media (max-width: 1300px) {
    /* font-size: 0.9rem; */
  }

  line-height: 1.5rem;
`;

export const PageWrapper = styled.div`
  /* border: 0.1rem solid red; */
  width: 100%;
  flex: 1;
  align-self: center;
`;

export const Section = styled.div`
  /* border: 1px solid blue; */
  flex: ${(props) => props.flex || "none"};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: ${(props) => props.color || textColor["primary"]};
  padding: ${(props) => props.padding || "0"};
  background: ${(props) =>
    props.background ||
    "linear-gradient(170deg, #0d1f33 0%, #0d1f33 1%, #8fccc7)"};

  @media (max-width: 1300px) {
    padding: 1rem;
  }
  padding: 1rem;
`;

export const Headline = styled.div`
  /* border: 1px solid blue; */
  padding: ${(props) => props.padding || "0"};
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  /* height: ${(props) => props.height || "4rem"}; */
  min-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: ${(props) => props.maxWidth || "100%"};
`;

export const Block = styled.div`
  /* border: 2px dotted green; */
  padding: ${(props) => props.padding || "1.0rem 1.0rem"};
  min-width: 20rem;
  flex: 1;
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || "center"};
`;

export const TwoColumns = styled.div`
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ThreeColumns = styled.div`
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const OneColumn = styled.div`
  /* border: 1px solid blue; */
  padding: 1rem;
  flex: 1;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 38rem) {
    align-items: center;
  }
`;

export const FeatureRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding || "0"};
  > * {
    &:nth-child(1) {
      margin-bottom: 1rem;
    }
    margin-top: 0.8rem;
  }
`;

export const InfoLine = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  height: ${(props) => props.height || "3rem"};
`;

export const ButtonRow = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  margin: 0 0 2rem 0;
  @media (max-width: 650px) {
    flex-direction: column;
    > * {
      ${(props) => props.doNotHide ? "margin: 0 0 1rem 0;": ""}
      &:nth-child(2) {
        ${(props) => props.doNotHide ? "": "display: none;"}
      }
      &:nth-child(3) {
        ${(props) => props.doNotHide ? "": "display: none;"}
        ${(props) => props.doNotHide ? "": "display: none;"}
      }
    }
  }
`;

export const HeroImage = styled.div`
  /* border: 1px solid blue; */
  max-width: ${(props) => props.maxWidth || "50rem"};
  &:hover {
    cursor: pointer;
  }
`;

export const AAImage = styled.img`
  /* border: 0.1rem solid red; */
  width: 100%;
  /* max-width: ${(props) => props.maxWidth || "50rem"}; */
  ${(props) => props.maxWidth ? `max-width: ${props.maxWidth};`: ``}
  /* max-height: 2rem; */
  ${(props) => props.maxHeight ? `max-height: ${props.maxHeight};`: ``}
  &:hover {
    cursor: pointer;
  }
  `;

export const VideoImage = styled.div`
/* border: 0.1rem solid red; */
max-width: 40rem;
width: 100%;
&:hover {
  cursor: pointer;
}
`;

export const VideoBlock = styled.div`
  /* border: 0.1rem solid red; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
