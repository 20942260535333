import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  /* border: 1px solid red; */
  border-radius: 0.3rem;
  padding: 0.2rem 1rem;
  color: white;
  background-color: ${(props) => props.color || "red"};
  margin: ${(props) => props.margin || "0"};
`;

const Tag = ({ name, color, margin }) => {
  return (
    <Container color={color} margin={margin}>
      {name}
    </Container>
  );
};

export default Tag;
