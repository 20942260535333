import React, { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { changeEmail } from "../../store/reducers/user/user-reducer";

import {
  FormInput,
  LabelAndError,
  AAButton,
  SignButtonRow,
} from "../StyledElements";

import Spinner from "../shared/Spinner";

const Container = styled.div`
  padding: 0 0 2rem 0;
  min-heigt: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const EmailChangeForm = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { profile, onCancel, onAfterEmailChanged } = props;
  const { email } = profile;

  const initialValues = { email };

  const validate = (values) => {
    const errors = {};
    // eslint-disable-next-line
    const emailIsValie = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email);
    if (!emailIsValie) {
      errors.email = "email not valid";
      setErrorMessage(errors.email);
    } else {
      setErrorMessage("");
    }
    return errors;
  };

  const onSubmit = async (values, params) => {
    const { email } = values;
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.updateUserAttributes(user, { email });
      await dispatch(changeEmail({ email }));
      onAfterEmailChanged();
    } catch (error) {
      if (error.code === "AliasExistsException") {
        setErrorMessage("is already in use")
      } else {
        setErrorMessage(error.code);
      }
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => {
            const disabled = isSubmitting || !isValid || !dirty || !!errorMessage;
          return (
            <>
              <LabelAndError label={"email"} error={errorMessage} />
              <FormInput
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                className="data-hj-allow"
                minWidth="40%"
              />
              <SignButtonRow>
                <AAButton
                  onClick={disabled ? () => {} : handleSubmit}
                  variant={isValid && dirty ? "success" : "outline"}
                  width="8rem"
                  disabled={disabled}
                >
                  {!isSubmitting ? "change email" : <Spinner />}
                </AAButton>
                <AAButton
                  onClick={isSubmitting ? () => {} : onCancel}
                  variant="secondary"
                  width="8rem"
                >
                  {"cancel"}
                </AAButton>
              </SignButtonRow>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default EmailChangeForm;
