import React from "react";
import styled from "styled-components";

import { textColor } from "../StyledElements";

// https://material.io/resources/icons/?style=baseline
// oder
// https://fonts.google.com/icons

export const FontawsomeIconBox = styled.div`
  /* border: 1px solid green; */
  width: ${(props) => props.width || "3rem"};
  display: flex;
  align-items: center;
`;
export const MaterialIconBox = styled.div`
  /* border: 1px solid green; */
  width: ${(props) => props.width || "3rem"};
  margin: ${(props) => props.margin || "0"};
  color: ${(props) => props.color || textColor["primary"]};
  display: flex;
  align-items: center;
  color: ${(props) => textColor[props.color || "primary"]};
  > * {
    font-size: ${(props) => props.fontSize || "34px"};
  }
`;

const Icon = ({ name, fontSize, width, color, margin, onClick = () => {} }) => {
  const isFontAwsome =
    name && ["fa-apple", "fa-windows", "fa-book"].includes(name);

  if (isFontAwsome) {
    const size = fontSize || "2x";
    return (
      <FontawsomeIconBox fontSize={fontSize} width={width} onClick={onClick}>
        <i className={`fa fa-${size} ${name}`} />
      </FontawsomeIconBox>
    );
  }
  const isMaterialDesign = name && !isFontAwsome;

  const className = "material-icons";

  if (isMaterialDesign)
    return (
      <MaterialIconBox
        fontSize={fontSize}
        width={width}
        color={color}
        margin={margin}
        onClick={onClick}
      >
        <i className={className}>{name}</i>
      </MaterialIconBox>
    );

  return null;
};

export default Icon;
