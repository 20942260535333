import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Label, Link } from "../StyledElements";

import OneLicense from "./OneLicense";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    border: 0.1rem solid lightgray;
    border-width: 1px 0 0 0;
    padding: 1rem 0 2rem 1rem;
    &:nth-child(1) {
      padding: 0 0 2rem 1rem;
      border-width: 0;
    }
  }
`;

const LegacyInfo = styled.div`
  border: 1px solid red;
  max-width: 30rem;
  color: gray;
  font-size: 0.9rem;
`;

const LinktToShop = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin: 0 0 0 0.5rem;
  }
`;

const Licenses = (props) => {
  const navigate = useNavigate();

  const serials = useSelector((state) => state.user.serials);
  if (!serials || serials.length === 0) return <Label>you have no licenses yet</Label>;

  let ownsLegacyPlugIns = false;
  let ownsUpdate = false;

  const items = serials.map((serial) => {
    const {
      itemId,
      product,
      serialNumber,
      files,
      authCounter,
      maxNumberOfAuths,
    } = serial;
    if (product === "LATENCY BUNDLE1") return null;

    if (product === "OBELISK1" || product === "QUARTZ1")
      ownsLegacyPlugIns = true;

    if (product === "OBELISK" || product === "QUARTZ") ownsUpdate = true;

    const productName =
      product === "OBELISK1"
        ? "OBELISK (legacy)"
        : product === "QUARTZ1"
        ? "QUARTZ (legacy)"
        : product;
    return (
      <OneLicense
        key={itemId}
        itemId={itemId}
        product={product}
        productName={productName}
        serialNumber={serialNumber}
        files={files}
        authCounter={authCounter}
        maxNumberOfAuths={maxNumberOfAuths}
      />
    );
  });

  return (
    <Container>
      {ownsLegacyPlugIns && !ownsUpdate && (
        <LegacyInfo>
          <p>A note about legacy versions:</p>
          <p>
            Older versions before v1.2 are no longer supported. With v1.2 we
            have completely reworked the plug-ins and now use a new
            authorization method. Therefore, older versions before v1.2 can
            unfortunately no longer be authorized.
          </p>
          <p>
            Please update to the latest versions, the updates are available for
            a small fee.
          </p>
          <LinktToShop>
            Get your updates in the{" "}
            <Link key="terms" onClick={() => navigate("/shop")}>
              {"shop"}
            </Link>
          </LinktToShop>
        </LegacyInfo>
      )}
      {items}
    </Container>
  );
};

export default Licenses;
