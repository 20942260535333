import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { API, Auth } from "aws-amplify";
import { setPrices } from "../prices/prices-reducer";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuth: false,
    profile: {},
    serials: [],
    orders: [],
    isLoading: {
      userData: false,
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = {
        ...state.isLoading,
        ...action.payload,
      };
    },
    setUserData: (state, action) => {
      const { user, serials, orders } = action.payload;
      state.isAuth = true;
      state.profile = user;
      state.serials = serials;
      state.orders = orders;
    },
    setUserProfile: (state, action) => {
      const { payload } = action;
      state.isAuth = true;
      state.profile = payload;
    },
    updateUserProfile: (state, action) => {
      const { payload } = action;
      state.profile = { ...state.profile, ...payload };
    },
    addRedeemedProduct: (state, action) => {
      const { payload } = action;
      const { serials, order } = payload;
      state.serials = [...serials, ...state.serials];
      state.orders = [order, ...state.orders];
    },
    clearUserProfile: (state) => {
      // TODO: wie kommt man hier an den initialState ran?
      state.isAuth = false;
      state.profile = {};
      state.serials = [];
      state.orders = [];
    },
  },
});

// --- async actions ---

export const fetchUser = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(userSlice.actions.setIsLoading({ userData: true }));

      // --- get user data ---
      const data = await API.get("aa-api", `/profile`);
      dispatch(userSlice.actions.setUserData(data));
      dispatch(setPrices(data.prices));

      // --- update emailVerified ---
      const { attributes } = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const { email_verified: emailVerified } = attributes;
      dispatch(userSlice.actions.updateUserProfile({ emailVerified }));
      // console.log("fetchUser()", JSON.stringify({ emailVerified }, null, 2));

      dispatch(userSlice.actions.setIsLoading({ userData: false }));
    } catch (error) {
      // console.log(error);
    }
  };
};

export const updateUser = (userParams) => {
  return async (dispatch, getState) => {
    try {
      const updatedUser = await API.put("aa-api", `/profile`, {
        body: userParams,
      });
      dispatch(userSlice.actions.setUserProfile(updatedUser));
    } catch (error) {
      // console.log(error);
    }
  };
};

export const changeEmail = (userParams) => {
  return async (dispatch, getState) => {
    try {
      const updatedUser = await API.put("aa-api", `/profile/email`, {
        body: userParams,
      });
      dispatch(userSlice.actions.setUserProfile(updatedUser));

      const { attributes } = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const { email_verified: emailVerified } = attributes;
      // console.log("changeEmail()", JSON.stringify({ emailVerified }, null, 2));
      dispatch(userSlice.actions.updateUserProfile({ emailVerified }));
    } catch (error) {
      // console.log(error);
    }
  };
};

export const redeemCoupon = (couponId) => {
  return async (dispatch, getState) => {
    try {
      const data = await API.post("aa-api", `/profile/coupon`, {
        body: { couponId },
      });
      const { errorMessage, orderData } = data;
      if (!!errorMessage) return { errorMessage };
      dispatch(userSlice.actions.addRedeemedProduct(orderData));
    } catch (error) {
      console.log(error);
    }
    return {};
  };
};

export const getUserId = createSelector(
  (state) => state.user,
  (user) => user.profile.userId,
);

export const {
  setUserData,
  setUserProfile,
  updateUserProfile,
  clearUserProfile,
  setLicenses,
} = userSlice.actions;

export default userSlice.reducer;
