import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Link, IconButton, TextLine } from "../StyledElements";

import {
  toggleAcceptConditions,
  clearAcceptConditions,
} from "../../store/reducers/cart/cart-reducer";

const Container = styled.div`
  /* border: 1px solid blue; */
  flex: 1;
  width: 100%;
  min-width: 1rem;
  margin: 0;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  > * {
    &:nth-child(1) {
      padding-right: 1rem;
    }
  }
`;

const AGBs = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const acceptedConditions = useSelector(
    (state) => state.cart.acceptedConditions,
  );

  useEffect(() => {
    return () => {
      dispatch(clearAcceptConditions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div key="button">
        <IconButton
          variant="secondary"
          className="material-icons"
          onClick={() => dispatch(toggleAcceptConditions())}
        >
          {acceptedConditions ? "check" : ""}
        </IconButton>
      </div>
      <TextLine key="text-line">
        <span key="text-1">I have read the</span>
        <Link
          key="terms"
          padding="0 0 0 3px"
          onClick={() => navigate("/terms")}
        >
          {"Terms and Conditions"}
        </Link>
        <span key="text-2">and</span>
        <Link key="privacy" onClick={() => navigate("/privacy")}>
          {"Privacy Statement "}
        </Link>
        <div key="text-3">and accept both</div>
      </TextLine>
    </Container>
  );
};

export default AGBs;
