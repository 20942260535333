import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { setStripeInProgress } from "../../store/reducers/app/app-reducer";

import { Link, Icon, TextLine, AAButton, TextBox } from "../StyledElements";
import { isCartEmpty } from "../../store/reducers/cart/cart-reducer";

import { Block } from "../ResponsiveElements";

import LoginForm from "../authentication/LoginForm";
import Cart from "./Cart";
import Products from "./Products";
import AGBs from "./AGBs";
import Paypal from "../shop-payment/Paypal";
import PaypalModal from "../shop-payment/PaypalModal";
import StripeModal from "../shop-payment/StripeModal";

export const ShopWrapper = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
`;

const Content = styled.div`
  /* border: 1px solid red; */
  flex: 1;
  width: 100%;
  max-width: 80rem;
  min-width: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ShopArea = styled.div`
  /* border: 1px solid red; */
  display: flex;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  /* width: 100%; */
  justify-content: space-between;
  padding: 0 0 3rem 0;
`;

export const Spacer = styled.div`
  flex-basis: 5rem;
  @media (max-width: 1000px) {
    flex-basis: 2rem;
  }
`;

const Hint = styled.div`
  /* border: 1px solid green; */
  color: red;
  height: 7rem;
  width: 100%;
  max-width: 30rem;
  disabled: true;
  align-self: flex-start;
`;

const CountrySelector = styled.div`
  /* border: 1px solid green; */
  padding: 3rem 0 0 0;
`;

const HintToLogin = styled.div`
  /* border: 1px solid magenta; */
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 0.5rem;
  }
  @media (max-width: 950px) {
    align-self: center;
    max-width: 30rem;
  }
`;

export default function Shop(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stripeInProgress = useSelector((state) => state.app.stripeInProgress);

  const isAuthenticated = useSelector((state) => state.user.isAuth);
  let profileHasCountry = !!useSelector(
    (state) =>
      state.user && state.user.profile && state.user.profile.countryCode,
  );

  const cartIsEmpty = useSelector(isCartEmpty);
  const acceptedConditions = useSelector(
    (state) => state.cart.acceptedConditions,
  );

  const userMustLogin = !isAuthenticated && !cartIsEmpty;
  const orderCanBePlaced = isAuthenticated && !cartIsEmpty && profileHasCountry;
  const userMustAddCountry =
    isAuthenticated && !cartIsEmpty && !profileHasCountry;
  const hintToLoginForSpecialPrices = !isAuthenticated && !userMustLogin;

  return (
    <ShopWrapper>
      <Content>
        <PaypalModal key="payment-modal" />
        <StripeModal key="stripe-modal" />
        <ShopArea key="shop-area">
          <Products key="products" />
          <Spacer key="spacer" />
          <Cart key="cart" />
        </ShopArea>

        {orderCanBePlaced && <AGBs />}

        {userMustAddCountry && (
          <Block key="country">
            <CountrySelector>
              <TextLine key="text-line">
                <span>Please select a country in your </span>
                <Link
                  key="terms"
                  padding="0 0 0 3px"
                  onClick={() => navigate("/dashboard/account")}
                >
                  {"account"}
                </Link>
                <span> to continue.</span>
              </TextLine>
            </CountrySelector>
          </Block>
        )}

        <Block key="hint" padding="0 0 0 0.8rem">
          {orderCanBePlaced && !acceptedConditions && (
            <Hint>
              <Icon variant="error" fontSize="2rem" className="material-icons">
                north
              </Icon>
              <div>please read and accept to continue to checkout</div>
            </Hint>
          )}
        </Block>

        {!stripeInProgress && orderCanBePlaced && acceptedConditions && (
          <Block key="paypal">
            <Paypal />
          </Block>
        )}

        {!stripeInProgress && orderCanBePlaced && acceptedConditions && (
          <Block key="stripe" alignItems="flex-start">
            <AAButton
              variant="secondary"
              margin="1rem 0"
              onClick={() => {
                dispatch(setStripeInProgress(true));
              }}
            >
              <Icon name="compress" width="2rem" />
              <TextBox>pay with credit card</TextBox>
            </AAButton>
          </Block>
        )}

        {hintToLoginForSpecialPrices && (
          <Block
            key="login-hint"
            // padding="1rem 0 0 1rem"
            alignItems="flex-start"
          >
            <HintToLogin>
              <span>please</span>
              {/* <Span>Please head over to your</Span> */}
              <Link key="terms" onClick={() => navigate("/login")}>
                {"login"}
              </Link>
              <span>to see special update prices</span>
            </HintToLogin>
          </Block>
        )}

        {userMustLogin && (
          <Block
            key="login-hint"
            padding="1rem 0 0 1rem"
            alignItems="flex-start"
          >
            <span>please login to your account:</span>
          </Block>
        )}
        {userMustLogin && (
          <Block key="login-form" alignItems="flex-start" padding="0">
            <LoginForm />
          </Block>
        )}

        {/* <div class="footer-spacer" /> */}
      </Content>
    </ShopWrapper>
  );
}
