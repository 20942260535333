import React from "react";
import styled from "styled-components";

const Container = styled.li`
  padding: 1rem;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.imageRight ? "row" : "row-reverse")};
  margin-bottom: 5rem;

  @media (max-width: 37.5em) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
  }
`;

const TextBox = styled.div`
  /* border: 1px solid magenta; */
  min-height: 2rem;
  flex: 1;
  order: 1;
  text-align: ${(props) => (props.imageRight ? "right" : "left")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > * {
    padding: ${(props) => (props.imageRight ? "0 3rem 0 0" : "0 0 0 3rem")};
  }
  @media (max-width: 37.5em) {
    justify-content: center;
    text-align: center;
    margin: 0 0 2rem 0;
    > * {
      padding: 0;
    }
  }
`;

const ImageBox = styled.div`
  /* border: 1px solid orange; */
  min-height: 2rem;
  overflow: hidden;
  flex: 1;
  order: 2;
  display: flex;
  justify-content: ${(props) => (props.imageRight ? "flex-start" : "flex-end")};
  @media (max-width: 37.5em) {
    order: 1;
  }
  max-height: ${(props) => props.maxHeight || "10rem"};
`;

const Image = styled.img`
  object-fit: scale-down;
  height: 100%;
  @media (max-width: 37.5em) {
    height: auto;
  }
  max-width: 100%;
  max-height: 100%;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1.5rem;
`;

const Description = styled.div`
  @media (max-width: 37.5em) {
    text-align: center;
  }
`;

const PlugInFeature = (props) => {
  const { content } = props;

  const { title, description, imageUrl, alt, maxHeight, imageRight } =
    content || {};

  return (
    <Container key={imageUrl} imageRight={imageRight}>
      <TextBox key="text" imageRight={imageRight}>
        <Title key="function-title">{title}</Title>
        <Description key="function-description">{description}</Description>
      </TextBox>

      <ImageBox key="image" maxHeight={maxHeight} imageRight={imageRight}>
        <Image src={imageUrl} alt={alt} />
      </ImageBox>
    </Container>
  );
};

export default PlugInFeature;
