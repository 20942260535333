import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../store/reducers/cart/cart-reducer";
import { fetchUser } from "../../store/reducers/user/user-reducer";

import { setStripeInProgress } from "../../store/reducers/app/app-reducer";

import { cartDetails } from "../../store/reducers/cart/cart-reducer";

import { generateInvoiceNumber } from "../../helpers/helpers";

import { Icon, AAButton, TextBox, TextLine } from "../StyledElements";
import Spinner from "../shared/Spinner";

const StripeCheckoutForm = (props) => {
  const { clientSecretIsLoading } = props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartDetailsForOrder = useSelector(cartDetails);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cardFormIsComplete, setCardFormIsComplete] = useState(false);
  const [paymentIsProceeding, setPaymentIsProceeding] = useState(false);

  const elementsAreLoading = isLoading || clientSecretIsLoading;

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // https://stripe.com/docs/js/payment_intents/confirm_payment#confirm_payment_intent-options-redirect

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "http://localhost:3333/stripe",  // wird bei Creditkarte nicht benötigt!
      },
      redirect: "if_required",
    });

    if (error) {
      console.log(JSON.stringify({ error }, null, 2));
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      switch (paymentIntent.status) {
        case "succeeded":
          const { id: stripePaymentId } = paymentIntent;

          const orderParams = {
            ...cartDetailsForOrder,
            invoiceNumber: generateInvoiceNumber(),
            paymentMethod: "CREDITCARD",
            stripePaymentId,
          };
          if (process.env.REACT_APP_STAGE === "dev")
            console.log(JSON.stringify({ orderParams }, null, 2));

          await API.post("aa-api", `/order`, { body: orderParams });
          dispatch(clearCart());
          dispatch(fetchUser());
          dispatch(setStripeInProgress(false));
          setPaymentIsProceeding(false);
          navigate("/thanks");
          break;

        default:
          setErrorMessage("Something went wrong.");
          break;
      }
    }
  };

  return (
    <form>
      {elementsAreLoading && <Spinner variant="primary" />}
      {elementsAreLoading && (
        <TextLine variant="primary" padding="1rem 0.5rem">
          connecting
        </TextLine>
      )}
      <PaymentElement
        id="payment-element"
        onChange={(event) => {
          setCardFormIsComplete(event.complete);
        }}
        onReady={() => {
          setIsLoading(false);
        }}
      />
      {!elementsAreLoading && (
        <AAButton
          key="submit"
          variant="secondary"
          margin="1rem 0"
          disabled={!cardFormIsComplete || paymentIsProceeding}
          onClick={
            cardFormIsComplete
              ? () => {
                  console.log("buy button clicked!");
                  setPaymentIsProceeding(true);
                  handleSubmit();
                }
              : () => {}
          }
        >
        {paymentIsProceeding && <Spinner variant="secondary" />}
        {!paymentIsProceeding &&  <TextBox>{"buy"}</TextBox>}
        </AAButton>
      )}
      <AAButton
        key="cancel"
        variant="dark"
        margin="1rem 0"
        onClick={() => {
          dispatch(setStripeInProgress(false));
        }}
      >
        <Icon name="compress" width="2rem" />
        <TextBox>cancel</TextBox>
      </AAButton>
      {errorMessage && (
        <TextLine key="error-message" variant="error">
          {errorMessage}
        </TextLine>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
