import styled from "styled-components";

export const backgroundColor = {
  primary: "#888", // 1D3557
  secondary: "#666", // 457B9D
  success: "#239647",
  light: "#EEE", // F1FAEE
  dark: "#d45d39", // A8DADC
  simple: "transparent",
  outline: "transparent",
  warning: "orange",
};

export const backgroundColorDisabled = {
  primary: "#888", // 1D3557
  secondary: "#666", // 457B9D
  success: "#95ab9c",
  light: "#EEE", // F1FAEE
  dark: "#d45d39", // A8DADC
  simple: "transparent",
  outline: "transparent",
  warning: "orange",
};

const borderColor = {
  primary: "#777", // 0d1726
  secondary: "#666", // 345d78
  success: "#239647",
  light: "#DDD", // dee8da
  dark: "#eb653d",
  simple: "transparent", // dee8da
  outline: "#DDD",
  warning: "orange",
};

const borderColorDisabled = {
  primary: "#777", // 0d1726
  secondary: "#666", // 345d78
  success: "#95ab9c",
  light: "#DDD", // dee8da
  dark: "#eb653d",
  simple: "transparent", // dee8da
  outline: "#DDD",
  warning: "orange",
};

const backgroundColorHover = {
  primary: "#777", // 0d1726
  secondary: "#888", // 345d78
  success: "#239647",
  light: "#DDD", // dee8da
  dark: "#eb653d",
  simple: "#DDD", // dee8da
  outline: "#DDD",
  warning: "orange",
};

export const textColor = {
  primary: "#555",
  secondary: "white",
  success: "white",
  light: "black",
  dark: "white",
  error: "#d45d39",
  simple: "#555",
  outline: "#555",
  warning: "orange",
  verified: "#239647",
};

// --- Layout ---

export const MainContainer = styled.div`
  font-family: "Roboto Mono", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export const AppContainer = styled.div`
  /* border: 1px solid red; */
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 80rem;
  @media (max-width: 1300px) {
    padding: 0 1rem;
  }
`;

export const ShopElement = styled.div`
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${(props) => props.padding || "0"};

  @media (max-width: 1000px) {
    /* flex-direction: column; */
    max-width: 600px;
  }
`;

export const ShopAndCart = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
    max-width: 600px;
    > * {
      &:nth-child(2) {
        /* devider */
        display: none;
      }
      &:nth-child(3) {
        /* cart */
        margin-top: 4rem;
        border-width: 0;
        padding: 1rem 0 0 0;
        > * {
          padding: 1rem 1rem 0 1rem;
        }
      }
    }
  }
`;

export const InfoText = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  > * {
    margin: 0 0 0 0.5rem;
  }
`;

// ---- Link ---

export const Link = styled.div`
  display: flex;
  max-width: auto;
  align-items: center;
  margin-right: 0.3rem;
  border: 1px solid transparent;
  border-color: transparent transparent ${backgroundColor.secondary} transparent;
  padding: ${(props) => props.padding || "0"};

  &:hover {
    border-color: transparent transparent ${backgroundColor.primary} transparent;
    cursor: pointer;
    color: ${backgroundColor.primary};
  }
  /* border: 1px solid green; */
`;

export const SignLink = styled.div`
  /* border: 1px solid green; */
  display: flex;
  padding: ${(props) => props.padding || "0"};

  > * {
    border: 1px solid transparent;
    &:nth-child(1) {
      margin-right: 0.4rem;
    }
    &:nth-child(2) {
      border-color: transparent transparent ${backgroundColor.secondary}
        transparent;
      color: ${backgroundColor.secondary};
      &:hover {
        border-color: transparent transparent ${backgroundColor.primary}
          transparent;
        cursor: pointer;
        color: ${backgroundColor.primary};
      }
    }
  }
`;

export const TextBox = styled.div`
  padding: ${(props) => props.padding || "0 0.8rem 0 0"};
`;

export const TextLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: ${(props) => textColor[props.variant] || textColor.primary};
  padding: ${(props) => props.padding || "0"};
  > * {
    flex-basis: auto;
    min-width: auto;
    margin: 0;
    padding-right: 0.5rem;
  }
`;

// ---- Sign Up / In ---

export const FormBox = styled.div`
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 0 0;
`;

export const SignContainer = styled.div`
  width: 400px;
  @media (max-width: 400px) {
    width: auto;
  }
`;

export const SignButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: center;
  padding: ${(props) => props.padding || "2rem 0 0 0"};

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 2rem 0 0 0;
    align-items: flex-start;
    justify-content: space-between;
    > * {
      &:nth-child(1) {
        margin-bottom: 2rem;
      }
      &:nth-child(2) {
        padding-right: 2rem;
      }
    }
  }
`;

// ---- Shop ---

export const ShopAmount = styled.div`
  /* border: 1px solid blue; */
  padding: 0 0 0 1rem;
  display: flex;
  flex: shrink;
  justify-content: flex-end;
  align-items: center;
  font-size: ${(props) => props.fontSize || "1.1rem"};
`;

export const ShopCounter = styled.span`
  background-color: ${backgroundColor.primary};
  color: white;
  border-radius: 4px;
  padding: 0 0.4rem;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// ---- Icon & Button ---

// https://material.io/resources/icons/?style=baseline
// oder
// https://fonts.google.com/icons

export const Icon = styled.div`
  padding: ${(props) => props.padding || "0 0.5rem 0 0"};
  font-size: ${(props) => props.fontSize || "1.6rem"};
`;

export const IconButton = styled.div`
  border: 2px solid gray;
  padding: 0.1rem;
  border-radius: 0.4rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  :hover {
    transition: background-color 100ms ease-in-out;
    cursor: pointer;
    background-color: lightgray;
  }
`;

export const AAButton = styled.div`
  padding: ${(props) => props.padding || "0 1rem"};
  margin: ${(props) => props.margin || "0"};
  border-radius: 0.2rem;
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight || "2.5rem"};

  color: ${(props) => textColor[props.variant] || textColor.primary};
  background-color: ${(props) => (props.disabled ? backgroundColorDisabled[props.variant] : backgroundColor[props.variant]) || "primary"};

  border: 1px solid red;
  border-color: ${(props) => borderColor[props.variant] || "primary"};
  border-color: ${(props) => (props.disabled ? borderColorDisabled[props.variant] : borderColor[props.variant]) || "primary"};

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  /* border: 2px dashed red; */

  :hover {
    transition: background-color 100ms ease-in-out;
    ${(props) => props.hoverStyle}
    ${({ disabled }) => disabled ? "cursor: not-allowed;" : "cursor: pointer;"}
    ${(props) => !props.disabled && `background-color: ${backgroundColorHover[props.variant] || "primary"};`}
  }

  @media (max-width: 600px) {
    > * {
      &:nth-child(1) {
        padding: 0;
      }
      &:nth-child(2) {
        ${(props) => (props.hideTextOnSmallDevice ? "display: none" : "")}
      }
    }
  }
`;

// ---- Container ---

export const Container = styled.div`
  padding: ${(props) => props.padding || "2.5rem"};
`;

// ---- FormInput ---

export const FormInput = styled.input`
  border: 0.1rem solid lightgray;
  border-radius: 0.2em;
  min-width: ${(props) => props.minWidth || "100%"};
  flex: 1;
  padding: 5px;
  font-family: "Roboto Mono", sans-serif;
  &:focus,
  &:active {
    border: 0.1rem solid ${borderColor["success"]};
    outline: none;
  }
  ::placeholder {
    color: lightgray;
  }
  border-color: ${(props) =>
    props.isTouched ? borderColor["warning"] : "lightgray"};
`;

// ---- LabelAndError Component ----

export const Label = styled.div`
  display: flex;
  padding: ${(props) => props.padding || "0"};
  align-items: center;
  color: ${(props) => textColor[props.variant] || textColor["primary"]};
`;

const LabelAndErrorContainer = styled.div`
  /* border: 1px solid magenta; */
  display: flex;
  padding: 1rem 0 5px 0;
  > * {
    &:nth-child(1) {
      margin-right: 1rem;
    }
    &:nth-child(2) {
      color: ${textColor.error};
    }
  }
`;

export const GlobalError = styled.div`
  /* border: 1px solid magenta; */
  padding: 1rem 0 0 0;
  color: ${(props) => textColor[props.variant] || "black"};
  min-height: 4rem;
`;

export const LabelAndError = ({ label, error }) => {
  return (
    <LabelAndErrorContainer>
      <span>{label}</span>
      <span>{error}</span>
    </LabelAndErrorContainer>
  );
};

// --- Shop ---

export const ProductName = styled.div`
  display: flex;
  flex: 1;
  font-size: ${(props) => props.fontSize || "1.6rem"};

  @media (max-width: 1000px) {
    font-size: 1.2rem;
  }
  align-items: center;
`;

export const Badge = styled.div`
  border: 2px solid ${backgroundColor.dark};
  border-radius: 0.3rem;
  font-size: 1rem;
  color: ${backgroundColor.dark};
  flex: shrink;
  padding: 0.1rem 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PriceBox = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  flex: shrink;
  padding: 0 10px 0 0;
`;

export const UpdateLabel = () => (
  <Badge>
    <div>UPDATE</div>
  </Badge>
);

export const UpgradeLabel = () => (
  <Badge>
    <div>UPGRADE</div>
  </Badge>
);

export const LegalText = styled.div`
  padding: 0rem 1rem 30rem 1rem;
  height: auto;
  max-width: 50rem;
  width: 100%;

  h2,
  h3,
  h4 {
    padding: 2rem 0 1rem 0;
    font-size: 0.8rem;
  }

  h2 {
    font-size: 1.2rem;
  }
  h3 {
    line-height: 150%;
    font-size: 0.8rem;
  }
  h4 {
    font-size: 0.8rem;
  }
  p {
    line-height: 150%;
    font-size: 0.8rem;
    /* padding: 0 0 1rem 0; */
    a {
    }
  }
  ul {
    font-size: 0.8rem;
    li {
      line-height: 150%;
      list-style-type: square;
      list-style-position: inside;
    }
  }
  /* border: 1px solid magenta; */
`;
