import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector((state) => state.user.isAuth);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`/login?redirect=${pathname}${search}`} />
  );
}
