import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";

import { LegalText } from "../StyledElements";
import { Section, Block } from "../ResponsiveElements";

import termsMarkdown from "../../static-text/terms.md";
import GoBackButton from "../shared/GoBackButton";

const Terms = (props) => {
  const [content, setContent] = useState({ md: "" });

  useEffect(() => {
    fetch(termsMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setContent({ md });
      });
  }, []);

  return (
    <Section background="white">
      <Block padding="3rem 0" >
        <LegalText>
          <GoBackButton />
          <Markdown children={content.md} />
        </LegalText>
      </Block>
    </Section>
  );
};

export default Terms;
