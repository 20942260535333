export const downloadIds = {
  obelisk: {
    demoInstaller: {
      mac: "obelisk/OBELISK Demo Installer 1.2.dmg",
      win: "obelisk/OBELISK Demo Installer 1.2.zip",
    },
    manual: {
      german: "obelisk/OBELISK Benutzerhandbuch 1.2 (Deutsch).pdf",
      english: "obelisk/OBELISK Manual 1.2 (English).pdf",
    },
    currentVersion: "1.2"
  },
  quartz: {
    demoInstaller: {
      mac: "quartz/QUARTZ Demo Installer 1.2.dmg",
      win: "quartz/QUARTZ Demo Installer 1.2.zip",
    },
    manual: {
      german: "quartz/QUARTZ Benutzerhandbuch 1.2 (Deutsch).pdf",
      english: "quartz/QUARTZ Manual 1.2 (English).pdf",
    },
    currentVersion: "1.2"
  },
  syncdfam: {
    installer: {
      mac: "syncdfam/SyncDFAM Installer 1.1.0.pkg",
      win: "syncdfam/SyncDFAM Installer 1.1.0.zip",
    },
    manual: {
      english: "playdfam/PlayDFAM SyncDFAM manual 1.1.pdf",
    },
    currentVersion: "1.1.0"
  },
};
