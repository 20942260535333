import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { FormBox } from "../StyledElements";
import { Section, Block } from "../ResponsiveElements";

import ConfirmForm from "./ConfirmForm";

const Confirm = (props) => {
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const email = query.get("email");

  const afterConfirm = () => {
    navigate("/");
  };

  return (
    <Section background="white">
      <Block padding="1rem 2rem 1rem 1rem">
        <FormBox>
          <ConfirmForm afterConfirm={afterConfirm} email={email} />
        </FormBox>
      </Block>
    </Section>
  );
};

export default Confirm;
