const isVercel = "REACT_APP_VERCEL_ENV" in process.env;

export const STAGE =
  process.env.REACT_APP_STAGE === "dev"
    ? "dev"
    : isVercel && process.env.REACT_APP_VERCEL_ENV === "production"
    ? "prod"
    : !isVercel && process.env.REACT_APP_STAGE === "prod"
    ? "prod"
    : isVercel && process.env.REACT_APP_VERCEL_ENV === "preview"
    ? "dev"
    : !isVercel && process.env.REACT_APP_STAGE === "dev"
    ? "dev"
    : !isVercel && process.env.REACT_APP_STAGE === "local"
    ? "local"
    : "unknown";

// -----------------------------------------------------------------------

const REGION = "eu-central-1";

const local = {
  apiGateway: {
    REGION,
    URL: "https://y83u1gtu94.execute-api.eu-central-1.amazonaws.com",
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-central-1_htwq4aU05",
    USER_POOL_CLIENT_ID: "6ih4ok5kargnqd99rsaqoqqmbb",
    IDENTITY_POOL_ID: "eu-central-1:c8ad52f9-bccc-4133-92af-deaf413c410c",
  },
  bucket: "aa-files-dev",
};

const dev = {
  apiGateway: {
    REGION,
    URL: "https://nny6jgvy63.execute-api.eu-central-1.amazonaws.com",
  },
  cognito: {
    REGION,
    USER_POOL_ID: "eu-central-1_YM83rG8g9",
    USER_POOL_CLIENT_ID: "pn2uvqete1e2ejevon6fru651",
    IDENTITY_POOL_ID: "eu-central-1:b929163f-8780-4697-8313-62b85e408308",
  },
  bucket: "aa-files-dev",
};

// --- ACHTUNG: dies ist das LIVE PROD system !!! ---
const prod = {
  apiGateway: {
    REGION,
    URL: "https://ku1aidubod.execute-api.eu-central-1.amazonaws.com",
  },
  cognito: {
    REGION,
    // --- der alte aa-prod user pool!! ---
    USER_POOL_ID: "eu-central-1_9G3NQYBKN",
    USER_POOL_CLIENT_ID: "3n6jotq5h1lrv07mamuh18kgk9",
    IDENTITY_POOL_ID: "eu-central-1:a3437b1e-0766-47d9-a28f-c1ee28bf281d",
  },
  bucket: "aa-files-prod",
};

const config = {
  ...(STAGE === "prod"
    ? prod
    : STAGE === "dev"
    ? dev
    : STAGE === "local"
    ? local
    : {}),
};

export const pathToS3Bucket = `https://${
  config.bucket || ""
}.s3.eu-central-1.amazonaws.com`;

export const PAYPAL_CLIENT_ID =
  STAGE === "prod"
    ? "AdvsYnOGCv2Z0pWBBEYVxb3bZ7T_sqq8uqMrKCcu9tyIqeASRZDYKYCtWlmAorOaEqDhvNYEqDp9BzUN"
    : "test";

export const STRIPE_PUBLISHABLE_KEY =
  STAGE === "prod"
    ? "pk_live_51Hw4FlIfmOuwMjXWvvUlLC38P0yQOtrAzGZE9ibhzlOaeHQLwbisJ2C5NATfTM7vNVKlWEGMQo6AgnjCoEGwx1qy00izMWjKvF"
    : "pk_test_51Hw4FlIfmOuwMjXWtnIaXpJSfz25y5bzsf7egQ5wqitrXQgezGfINds5v2UcqP1hmQPio8t4iglbwH75ipvfxUCJ001vW4AUpm";

export default config;
