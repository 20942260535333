import React, { useState } from "react";
import styled from "styled-components";
import useClipboard from "react-use-clipboard";

import { TextBox, AAButton } from "../StyledElements";

import { Headline } from "../ResponsiveElements";

import Icon from "../shared/Icon";

import DownloadLink from "../shared/DownloadLink";

const Container = styled.div`
  witdh: 100%;
  min-width: 1rem;
`;

const DownloadsContainer = styled.div`
  /* border: 1px solid blue; */
  witdh: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1rem;
  align-items: flex-start;
  margin: 0;
  /* > * {
    padding: 1rem 0;
  } */
`;

const SerialNumber = styled.div`
  /* border: 1px solid red; */
  padding: 0 0 1rem 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  > * {
    margin: 0 1rem 0 0;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    > * {
      margin: 0 0 1rem 0;
    }
  }
`;

const OneLicense = (props) => {
  const [showEarlierInstaller, setShowEarlierInstaller] = useState(false);

  const { productName, serialNumber, files, authCounter, maxNumberOfAuths } =
    props;

  const { manuals = [], installer = [] } = files || {};
  const hasFiles = Object.keys(files).length > 0;
  const hasInstallers = Object.keys(installer).length > 0;

  const [, setCopied] = useClipboard(serialNumber);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const isLegacy =
    productName === "QUARTZ (legacy)" || productName === "OBELISK (legacy)";

  const handleCopyToClippboardButton = async () => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    setCopied();
    setShowCopiedMessage(true);
    await sleep(300);
    setShowCopiedMessage(false);
  };

  const hasEarlierInstallers =
    (((files || {}).installer || {}).earlier || []).length > 0;

  const EarlierInstallerButton = () => (
    <AAButton
      variant="simple"
      // padding="0.4rem 0.4rem"
      margin="1rem 0"
      onClick={() => setShowEarlierInstaller(!showEarlierInstaller)}
    >
      <Icon name={showEarlierInstaller ? "compress" : "expand"} width="2rem" />
      <TextBox>{`${
        showEarlierInstaller ? "hide" : "show"
      } older installer`}</TextBox>
    </AAButton>
  );

  return (
    <Container>
      <Headline key="product-name" fontWeight="bold" padding="2rem 0">
        {productName === "PLAY_DFAM" ? "PlayDFAM" : productName}
      </Headline>

      <SerialNumber key="serial-number">
        <div>{`Serial Number: ${serialNumber}`}</div>
        {!isLegacy && (
          <AAButton
            variant="outline"
            // padding="0.4rem 1rem"
            onClick={handleCopyToClippboardButton}
          >
            <Icon
              name={showCopiedMessage ? "done" : "content_copy"}
              width="2.5rem"
              fontSize="25px"
            />
            <span>copy to clipboard</span>
          </AAButton>
        )}
      </SerialNumber>

      {!isLegacy && (
        <TextBox key="auths" padding="1rem 0 2rem 0">
          <div key="auth-counter">
            {`${authCounter} authorized machine${authCounter > 1 ? "s" : ""}`}
          </div>

          <div key="allowed-auths">
            {`${maxNumberOfAuths - authCounter} authorizations left`}
          </div>
        </TextBox>
      )}

      {hasFiles && (
        <DownloadsContainer key="installer">
          {hasInstallers && (
            <DownloadLink
              platform="MAC"
              icon="computer"
              title={installer.latest.mac.title}
              downloadId={installer.latest.mac.downloadId}
            />
          )}
          {hasInstallers && (
            <DownloadLink
              platform="WIN"
              icon="computer"
              title={installer.latest.win.title}
              downloadId={installer.latest.win.downloadId}
            />
          )}

          {manuals.map(({ title, downloadId }) => {
            return (
              <DownloadLink
                key={downloadId}
                icon="menu_book"
                title={title}
                downloadId={downloadId}
              />
            );
          })}

          {hasEarlierInstallers && <EarlierInstallerButton />}

          {showEarlierInstaller &&
            files.installer.earlier.map(({ mac, win }) => [
              <DownloadLink
                key={`mac-${mac.downloadId}`}
                platform="MAC"
                icon="computer"
                title={mac.title}
                downloadId={mac.downloadId}
              />,
              <DownloadLink
                key={`win-${win.downloadId}`}
                platform="WIN"
                icon="computer"
                title={win.title}
                downloadId={win.downloadId}
              />,
            ])}
        </DownloadsContainer>
      )}
    </Container>
  );
};

export default OneLicense;
