import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

import Spinner from "../shared/Spinner";

import {
  FormBox,
  FormInput,
  LabelAndError,
  AAButton,
  SignContainer,
  SignButtonRow,
  GlobalError,
} from "../StyledElements";

const ResetPasswordConfirm = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const email = query.get("email");

  const initialValues = {
    email,
    code: "",
    password: "",
  };

  const message = "please choose a new password";

  const onSubmit = async (values, params) => {
    setErrorMessage("");
    try {
      const { email, code, password } = values;
      await Auth.forgotPasswordSubmit(email, code, password);
      navigate(`/login?email=${email}`);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message.toLowerCase());
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("invalid email address").required("required"),
    password: Yup.string().min(8, "(min 8 characters)").required("required"),
    code: Yup.string().matches(/^\d{6}$/, "6 digits"),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "does not match",
    ),
  });

  return (
    <FormBox>
      <GlobalError variant={errorMessage ? "error" : "black"}>
        {errorMessage || message}
      </GlobalError>
      <SignContainer>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <>
              <LabelAndError label={"email"} />
              <FormInput
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                className="data-hj-allow"
              />
              <LabelAndError
                label={"the code you received by email"}
                error={errors.code}
              />
              <FormInput
                name="code"
                type="text"
                onChange={handleChange}
                value={values.code}
              />

              <LabelAndError
                label={
                  !errors.password
                    ? "new password (min 8 characters)"
                    : "new password"
                }
                error={errors.password}
              />
              <FormInput
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
              />
              <LabelAndError
                label={"confirm new password"}
                error={errors.passwordConfirm}
              />
              <FormInput
                name="passwordConfirm"
                type="password"
                onChange={handleChange}
                value={values.passwordConfirm}
              />

              <SignButtonRow>
                <AAButton
                  onClick={handleSubmit}
                  variant="secondary"
                  width="15rem"
                >
                  {!isSubmitting ? "confirm new password" : <Spinner />}
                </AAButton>
              </SignButtonRow>
            </>
          )}
        </Formik>
      </SignContainer>
    </FormBox>
  );
};

export default ResetPasswordConfirm;
