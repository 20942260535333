import React, { useEffect } from "react";
import Modal from "react-modal";

import VideoPlayer from "./VideoPlayer";

Modal.setAppElement("#modal-root");

const modalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    zIndex: 1000,
  },
  content: {
    display: "flex",
    justifyContent: "center",
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    background: "transparent",
    overflow: "hidden",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    border: "0 solid transparent",
    // padding: "20px",
  },
};

const VideoPlayerModal = (props) => {
  const { url, onClose } = props;
  const isOpen = !!url;

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <VideoPlayer url={url} onClose={onClose} />
    </Modal>
  );
};

export default VideoPlayerModal;
