import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import YouTubeIcon from "@mui/icons-material/YouTube";
import Instagram from "@mui/icons-material/Instagram";

import { Section, Headline, Block, AAImage } from "../ResponsiveElements";

import { Link, AAButton } from "../StyledElements";

import Icon from "../shared/Icon";
import BuyNowButton from "../shared/BuyNowButton";

import { pathToS3Bucket } from "../../config";

import { backgroundColor } from "../StyledElements";

const HomeWrapper = styled.div`
  /* border: 0.1rem solid red; */
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  flex: 1;
  /* min-height: 1rem; */
`;

const Divider = styled.div`
  border: 0.1rem solid lightgray;
  border-width: 0 0 1px 0;
  width: 100%;
  max-width: 50rem;
  padding: 1rem;
`;

const TechInfo = styled.div`
  /* border: 0.1rem solid white; */
  flex: 1;
  align-items: center;
  display: flex;
  padding: 0.5rem 0;
  > * {
    &:nth-child(2) {
      padding-right: 1.5rem;
    }
  }
`;

const BuyBox = styled.div`
  /* border: 0.1rem solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.5rem 0;
  > * {
    &:nth-child(1) {
      margin: 0 1rem 0 0;
    }
  }
  @media (max-width: 30rem) {
    flex-direction: column;
    > * {
      &:nth-child(1) {
        margin: 0 0 1rem 0;
      }
    }
  }
`;

const FooterBoxLeft = styled.div`
  /* border: 1px solid red; */
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 0.8rem 0;
  @media (max-width: 700px) {
    align-items: center;
  }
`;

const SocialIcon = styled.div`
  font-size: 3.5rem;
  padding: 0;
`;
const SocialLink = styled.a`
  color: red;
`;

const FooterBoxMiddle = styled.div`
  /* border: 1px solid blue; */
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0 1rem;
  }
  @media (max-width: 700px) {
    /* border: 1px solid red; */
  }
`;

const FooterBoxRight = styled.div`
  /* border: 1px solid green; */
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 0 0.8rem 0;

  > * {
    margin: 0 0 0 2rem;
  }
  @media (max-width: 700px) {
    > * {
      padding: 2rem 0 0 0;
      margin: 0 0.5rem;
      justify-content: space-between;
      align-items: space-between;
    }
  }
`;

const FooterBlock = styled.div`
  /* border: 1px solid magenta; */
  flex: 1;
  padding: ${(props) => props.padding || "1rem 2.0rem 4rem 2.0rem"};
  width: 100%;
  max-width: 80rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    > * {
      padding: 1rem;
    }
  }
`;

export default function Home() {
  const navigate = useNavigate();

  return (
    <HomeWrapper>
      <Section key="plug-ins" background="white" padding="1rem">
        {process.env.REACT_APP_SHOW_NEW_PRODUCT === "true" && (
          <>
            <Block key="playdfam">
              <Headline
                key="title"
                fontSize="2.0rem"
                fontWeight="bold"
                padding="1rem 0"
              >
                PlayDFAM
              </Headline>
              <Headline key="subtitle" padding="0 0 1rem 0">
                play and sync MOOG DFAM to your DAW
              </Headline>

              <AAImage
                key="playdfam-image"
                src={`${pathToS3Bucket}/playdfam-web/play-dfam-gui-01-v1-1.png`}
                alt="playdfam"
                maxWidth="25rem"
                onClick={() => navigate("/playdfam")}
              />
              <TechInfo key="playdfam-tech">
                <Icon name="fa-apple" width="2rem" />
                <div>AU VST VST3</div>
                <Icon name="fa-windows" width="2.5rem" />
                <div>VST VST3</div>
              </TechInfo>
              <Headline key="price" fontWeight="bold" padding="1rem">
                59 EUR/USD
              </Headline>
              <BuyBox key="learn-more-button">
                <BuyNowButton key="buy-now-button" />
                <AAButton
                  key="learn-more-button"
                  onClick={() => navigate("/playdfam")}
                  variant="secondary"
                  width="10rem"
                >
                  {"learn more"}
                </AAButton>
              </BuyBox>
            </Block>

            <Divider key="divider-5" />

            <Block key="syncdfam">
              <Headline
                key="title"
                fontSize="2.0rem"
                fontWeight="bold"
                padding="1rem 0"
              >
                SyncDFAM
              </Headline>
              <Headline key="subtitle" padding="0 0 1rem 0">
                PlayDFAM's free little sibling
              </Headline>

              <AAImage
                key="syncdfam-image"
                src={`${pathToS3Bucket}/syncdfam-web/sync-dfam-gui-01.png`}
                alt="syncdfam"
                maxWidth="25rem"
                onClick={() => navigate("/playdfam")}
              />
              <TechInfo key="syncdfam-tech">
                <Icon name="fa-apple" width="2rem" />
                <div>AU VST VST3</div>
                <Icon name="fa-windows" width="2.5rem" />
                <div>VST VST3</div>
              </TechInfo>
              <Headline key="price" fontWeight="bold" padding="1rem">
                it's free to use
              </Headline>
              <BuyBox key="learn-more-button">
                <AAButton
                  key="learn-more-button"
                  onClick={() => navigate("/playdfam")}
                  variant="secondary"
                  width="10rem"
                >
                  {"learn more"}
                </AAButton>
              </BuyBox>
            </Block>

            <Divider key="divider-4" />
          </>
        )}
        <Block key="obelisk">
          <Headline
            key="title"
            fontSize="2.0rem"
            fontWeight="bold"
            padding="1rem 0"
          >
            OBELISK
          </Headline>
          <Headline key="subtitle" padding="0 0 1rem 0">
            award-winning spectral delay and multi-effect
          </Headline>
          <AAImage
            key="obelisk-image"
            src={`${pathToS3Bucket}/obelisk-web/Obelisk_gui1.png`}
            alt="obelisk"
            maxWidth="25rem"
            onClick={() => navigate("/obelisk")}
          />
          <TechInfo key="obelisk-tech">
            <Icon name="fa-apple" width="2rem" />
            <div>AU VST VST3</div>
            <Icon name="fa-windows" width="2.5rem" />
            <div>VST VST3</div>
          </TechInfo>
          <Headline key="price" fontWeight="bold" padding="1rem">
            79 EUR | 89 USD
          </Headline>
          <BuyBox key="learn-more-button">
            <BuyNowButton key="buy-now-button" />
            <AAButton
              key="learn-more-button"
              onClick={() => navigate("/obelisk")}
              variant="secondary"
              width="10rem"
            >
              {"learn more"}
            </AAButton>
          </BuyBox>
        </Block>

        <Divider key="divider-3" />

        <Block key="quartz">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="3rem 0 1rem 0">
            QUARTZ
          </Headline>
          <Headline padding="0 0 1rem 0">
            multi effect that turns your sounds into ever evolving rhythmic
            soundscapes
          </Headline>
          <AAImage
            key="hero-image"
            maxWidth="40rem"
            onClick={() => navigate("/quartz")}
            src={`${pathToS3Bucket}/quartz-web/Quartz_gui1.png`}
            alt="quartz"
          />
          <TechInfo key="quartz-tech">
            <Icon name="fa-apple" width="2rem" />
            <div>AU VST VST3</div>
            <Icon name="fa-windows" width="2.5rem" />
            <div>VST VST3</div>
          </TechInfo>
          <Headline key="price" fontWeight="bold" padding="1rem">
            79 EUR | 89 USD
          </Headline>
          <BuyBox key="learn-more-button">
            <BuyNowButton key="buy-now-button" />
            <AAButton
              key="learn-more-button"
              onClick={() => navigate("/quartz")}
              variant="secondary"
              width="10rem"
            >
              {"learn more"}
            </AAButton>
          </BuyBox>
        </Block>

        <Divider key="divider-2" />

        <Block key="effectbundle">
          <Headline fontSize="2.0rem" fontWeight="bold" padding="3rem 0 1rem 0">
            EFFECT BUNDLE
          </Headline>
          <Headline padding="0 0 1rem 0">
            the effect bundle contains QUARTZ and OBELISK
          </Headline>
          <TechInfo key="effect-bundle-tech">
            <Icon name="fa-apple" width="2rem" />
            <div>AU VST VST3</div>
            <Icon name="fa-windows" width="2.5rem" />
            <div>VST VST3</div>
          </TechInfo>
          <Headline key="price" fontWeight="bold" padding="1rem">
            119 EUR | 129 USD
          </Headline>
          <BuyBox key="learn-more-button">
            <BuyNowButton key="buy-now-button" />
            <AAButton
              key="learn-more-button"
              onClick={() => navigate("/effectbundle")}
              variant="secondary"
              width="10rem"
            >
              {"learn more"}
            </AAButton>
          </BuyBox>
        </Block>

        <Divider key="divider-1" />
      </Section>

      <Section
        key="footer"
        background="white"
        // background="#333"
        // color="white"
        flex="1"
        padding="4rem 0 4rem 0"
      >
        <FooterBlock key="footer-block">
          {/* <TwoColumns key="2-columns"> */}
          <FooterBoxLeft key="box-left">
            <Headline key="price" padding="0.5rem 0 1rem 0">
              any question or suggestion?
            </Headline>

            <AAButton
              key="contact-us"
              onClick={() => {
                window.location.href = `mailto:hello@artificialaudio.com`;
              }}
              variant="secondary"
              width="10rem"
            >
              {"contact us"}
            </AAButton>
          </FooterBoxLeft>

          <FooterBoxMiddle key="box-left">
            <SocialLink
              href="https://www.youtube.com/@artificialaudio-plugins"
              target="_blank"
              rel="noreferrer"
              aria-label="artificial audio on youtube"
              aria-describedby="link to artificial audio youtube page"
            >
              <SocialIcon>
                <YouTubeIcon
                  sx={{ color: backgroundColor["secondary"], fontSize: 65 }}
                ></YouTubeIcon>
              </SocialIcon>
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/artificial_audio_plugins/"
              target="_blank"
              rel="noreferrer"
              aria-label="artificial audio on instagram"
              aria-describedby="link to artificial audio instagram page"
            >
              <SocialIcon>
                <Instagram
                  sx={{ color: backgroundColor["secondary"], fontSize: 50 }}
                ></Instagram>
              </SocialIcon>
            </SocialLink>
          </FooterBoxMiddle>

          <FooterBoxRight>
            <Link>
              <div
                onClick={() => {
                  window.location.href = `mailto:hello@artificialaudio.com`;
                }}
              >
                contact
              </div>
            </Link>

            <Link>
              <div onClick={() => navigate("/imprint")}>imprint</div>
            </Link>
            <Link>
              <div onClick={() => navigate("/terms")}>terms</div>
            </Link>
            <Link>
              <div onClick={() => navigate("/privacy")}>privacy</div>
            </Link>
          </FooterBoxRight>
        </FooterBlock>
      </Section>
    </HomeWrapper>
  );
}
