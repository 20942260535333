import React from "react";

import { Section, Block } from "../ResponsiveElements";

export default function NotFound() {
  return (
    <Section background="white" alignItems="flex-start">
    <Block padding="3rem 0">
      <p>Ups, page not found...</p>
    </Block>
  </Section>
  );
}
