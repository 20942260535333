import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUser,
  clearUserProfile,
} from "./store/reducers/user/user-reducer";

import { clearPrices } from "./store/reducers/prices/prices-reducer";
import { AppWrapper, PageWrapper } from "./components/ResponsiveElements";

import Navigation from "./components/navigation/Navigation";

import AppRoutes from "./components/routes/AppRoutes";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuth);

  useEffect(() => {
    const onLoad = async () => {
      try {
        // check if a user is logged in and refresh its token
        // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
        await Auth.currentSession();
        dispatch(fetchUser());
      } catch (e) {
        // if no user is logged in then currentSession() throws an error.
        // just to make sure, clear profile
        dispatch(clearUserProfile());
        dispatch(clearPrices());
      }
    };
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const IS_ONLINE = process.env.REACT_APP_IS_ONLINE === "true";
  const SHOW_WEBSITE = IS_ONLINE || isAuthenticated;

  // console.log(`REACT_APP_IS_ONLINE: ${IS_ONLINE ? "true" : "false"}`);

  return (
    <AppWrapper>
      {SHOW_WEBSITE && (
        <PageWrapper key="navigation">
          <Navigation />
        </PageWrapper>
      )}
      <PageWrapper key="content">
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </PageWrapper>
    </AppWrapper>
  );
}
export default App;
