import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { caluculatePricesForUser } from "../prices/prices-reducer";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    play_dfam: 0,
    obelisk: 0,
    quartz: 0,
    effectbundle: 0,
    acceptedConditions: false,
  },
  reducers: {
    incrementProduct: (state, action) => {
      const { product } = action.payload;
      if (state[product] === 0) state[product] += 1;
    },
    decrementProduct: (state, action) => {
      const { product } = action.payload;
      if (state[product] > 0) state[product] -= 1;
    },
    removeProduct: (state, action) => {
      const { product } = action.payload;
      if (state[product] > 0) state[product] = 0;
    },
    toggleAcceptConditions: (state) => {
      state.acceptedConditions = !state.acceptedConditions;
    },
    clearAcceptConditions: (state) => {
      state.acceptedConditions = false;
    },
    clearCart: (state) => {
      state.play_dfam = 0;
      state.obelisk = 0;
      state.quartz = 0;
      state.effectbundle = 0;
      state.acceptedConditions = false;
      state.paymentInProgress = false;
    },
  },
});

export const {
  incrementProduct,
  decrementProduct,
  removeProduct,
  toggleAcceptConditions,
  clearAcceptConditions,
  clearCart,
} = cartSlice.actions;

const decimalAmount = (price) => (price * 0.01).toFixed(2);

export const cartDetails = createSelector(
  (state) => state.user.profile,
  (state) => state.prices,
  (state) => state.cart,
  (profile, prices, cart) => {
    const { vatNumber } = profile;
    const userPrices = caluculatePricesForUser(profile, prices);
    const { currency, taxPercent = 0 } = userPrices.obelisk;

    const products = [];
    let total = 0;

    if (cart.play_dfam > 0) {
      const price = cart.play_dfam * userPrices.play_dfam.price;
      total += price;
      products.push({
        title: "PLAY_DFAM",
        count: cart.play_dfam,
        price: decimalAmount(price),
      });
    }

    if (cart.obelisk > 0) {
      const price = cart.obelisk * userPrices.obelisk.price;
      total += price;
      products.push({
        title: "OBELISK",
        count: cart.obelisk,
        price: decimalAmount(price),
      });
    }

    if (cart.quartz > 0) {
      const price = cart.quartz * userPrices.quartz.price;
      total += price;
      products.push({
        title: "QUARTZ",
        count: cart.quartz,
        price: decimalAmount(price),
      });
    }

    if (cart.effectbundle > 0) {
      const price = cart.effectbundle * userPrices.effectbundle.price;
      total += price;
      products.push({
        title: "EFFECTBUNDLE",
        count: cart.effectbundle,
        price: decimalAmount(price),
      });
    }

    const netAmount = Math.floor(total / (1 + taxPercent * 0.01));
    const taxAmount = total - netAmount;

    const orderDetails = {
      products,
      currency,
      taxPercent,
      total: decimalAmount(total),
      netAmount: decimalAmount(netAmount),
      taxAmount: decimalAmount(taxAmount),
      vatNumber,
    };

    return orderDetails;
  },
);

export const myCart = createSelector(
  (state) => state.user.profile,
  (state) => state.prices,
  (state) => state.cart,
  (profile, prices, cart) => {
    const userPrices = caluculatePricesForUser(profile, prices);
    const { currency, taxPercent } = userPrices.obelisk;
    const result = {
      currency,
      taxPercent,
      play_dfam: {
        count: cart.play_dfam,
        price: cart.play_dfam * userPrices.play_dfam.price,
        isUpdatePrice: userPrices.play_dfam.isUpdatePrice,
        unitPrice: userPrices.play_dfam.price,
      },
      obelisk: {
        count: cart.obelisk,
        price: cart.obelisk * userPrices.obelisk.price,
        isUpdatePrice: userPrices.obelisk.isUpdatePrice,
        unitPrice: userPrices.obelisk.price,
      },
      quartz: {
        count: cart.quartz,
        price: cart.quartz * userPrices.quartz.price,
        isUpdatePrice: userPrices.quartz.isUpdatePrice,
        unitPrice: userPrices.quartz.price,
      },
      effectbundle: {
        count: cart.effectbundle,
        price: cart.effectbundle * userPrices.effectbundle.price,
        isUpdatePrice: userPrices.effectbundle.isUpdatePrice,
        isUpgradePrice: userPrices.effectbundle.isUpgradePrice,
        unitPrice: userPrices.effectbundle.price,
      },
    };

    result.total =
      result.play_dfam.price +
      result.obelisk.price +
      result.quartz.price +
      result.effectbundle.price;
    if ("taxPercent" in userPrices.obelisk) {
      result.netAmount = Math.floor(
        result.total / (1 + userPrices.obelisk.taxPercent * 0.01),
      );
      result.taxAmount = result.total - result.netAmount;
    }
    return result;
  },
);

export const isCartEmpty = createSelector(
  (state) => state.cart,
  (cart) =>
    cart.play_dfam + cart.obelisk + cart.quartz + cart.effectbundle === 0,
);

export const numberOfItemsInCart = createSelector(
  (state) => state.cart,
  (cart) => cart.play_dfam + cart.obelisk + cart.quartz + cart.effectbundle,
);

export default cartSlice.reducer;
