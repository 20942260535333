import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { API, Auth } from "aws-amplify";

const initialState = {
  play_dfam: {
    isUpdatePrice: false,
    message: "",
    priceEU: {
      currency: "EUR",
      grossPrice: 5900,
      netPrice: 4958,
      taxAmount: 942,
      taxPercent: 19,
    },
    priceWorld: {
      currency: "USD",
      price: 5900,
    },
  },
  obelisk: {
    isUpdatePrice: false,
    message: "",
    priceEU: {
      currency: "EUR",
      grossPrice: 7900,
      netPrice: 6639,
      taxAmount: 1261,
      taxPercent: 19,
    },
    priceWorld: {
      currency: "USD",
      price: 8900,
    },
  },
  quartz: {
    isUpdatePrice: false,
    message: "",
    priceEU: {
      currency: "EUR",
      grossPrice: 7900,
      netPrice: 6639,
      taxAmount: 1261,
      taxPercent: 19,
    },
    priceWorld: {
      currency: "USD",
      price: 8900,
    },
  },
  effectbundle: {
    isUpdatePrice: false,
    isUpgradePrice: false,
    message: "",
    priceEU: {
      currency: "EUR",
      grossPrice: 11900,
      netPrice: 10000,
      taxAmount: 1900,
      taxPercent: 19,
    },
    priceWorld: {
      currency: "USD",
      price: 12900,
    },
  },
};

export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    setPrices: (state, action) => {
      const { play_dfam, obelisk, quartz, effectbundle } = action.payload;
      state.play_dfam = play_dfam;
      state.obelisk = obelisk;
      state.quartz = quartz;
      state.effectbundle = effectbundle;
    },
    clearPrices: (state) => {
      state.play_dfam = initialState.play_dfam;
      state.obelisk = initialState.obelisk;
      state.quartz = initialState.quartz;
      state.effectbundle = initialState.effectbundle;
    },
  },
});

// --- async actions ---

// diese Methode wird gerade gar nicht benutzt!
export const fetchPrices = () => {
  return async (dispatch, getState) => {
    let prices;
    try {
      await Auth.currentAuthenticatedUser({ bypassCache: true }); // throws Error if user is not logged in
      prices = await API.get("aa-api", `/userprices`);
      dispatch(setPrices(prices));
    } catch (error) {
      dispatch(clearPrices());
    }
  };
};

export const { setPrices, clearPrices } = pricesSlice.actions;

// a helper function that can be used in other selectors too:
export const caluculatePricesForUser = (profile, prices) => {
  if (process.env.REACT_APP_STAGE === "dev")
    console.log("caluculatePricesForUser", JSON.stringify({ prices }, null, 2));

  const {
    residentInEU = false,
    vatNumber = "",
    countryCode = "",
  } = profile || {};
  const result = {};
  for (const [
    product,
    { priceEU, priceWorld, isUpdatePrice, isUpgradePrice, message, badge },
  ] of Object.entries(prices)) {
    if (process.env.REACT_APP_STAGE === "dev")
      console.log(`product: ${product}`)
    if (residentInEU) {
      // EU
      const { currency, netPrice, grossPrice, taxPercent } = priceEU;
      result[product] =
        vatNumber && countryCode !== "de"
          ? {
              isUpdatePrice,
              isUpgradePrice,
              message,
              badge,
              currency,
              price: netPrice,
            }
          : {
              isUpdatePrice,
              isUpgradePrice,
              message,
              badge,
              currency,
              price: grossPrice,
              taxPercent,
            };
    } else {
      // world
      result[product] = {
        ...priceWorld,
        isUpdatePrice,
        isUpgradePrice,
        message,
        badge,
      };
    }
  }
  return result;
};

export const userPrices = createSelector(
  (state) => state.user.profile,
  (state) => state.prices,
  (profile, prices) => caluculatePricesForUser(profile, prices),
);
export default pricesSlice.reducer;
