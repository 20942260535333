import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

export const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 1rem 0;
  > * {
    border-radius: 0.2rem;
    margin: 0 0.8rem 0 0;
    &:hover {
      cursor: pointer;
      background-color: lightgray;
    }
  }
  user-select: none;
`;

const CheckBox = ({ isChecked, isUpdating, onClick = () => {} }) => {
  return (
    <Container>
      <Icon
        name={isChecked ? "check_box" : "check_box_outline_blank"}
        onClick={isUpdating ? () => {} : onClick}
        width="auto"
      />
      {isUpdating
        ? "saving..."
        : isChecked
        ? "I receive the newsletter"
        : "no newsletter subscription (click checkbox to subscribe)"}
    </Container>
  );
};

export default CheckBox;
