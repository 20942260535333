import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// --- setup Sentry ---

const isLocal = process.env.NODE_ENV === "development";

export function initSentry () {
  if (isLocal) {
    console.log("do not use sentry");
    return;
  }
  // console.log("init sentry");
  Sentry.init({
    dsn:
      "https://da4f152f5d6a45aeb5d708595c5163fa@o70465.ingest.sentry.io/5551835",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

