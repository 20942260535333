import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { Link } from "../StyledElements";
import { getUserId } from "../../store/reducers/user/user-reducer";
import { handleDownload } from "../../helpers/helpers";
import {
  currentDownloads,
  setDownloadIsActive,
  setDownloadIsDone,
} from "../../store/reducers/app/app-reducer";

import Icon from "./Icon";

export const Container = styled.div`
  /* border: 1px solid blue; */
  height: ${(props) => props.height || "3rem"};
  display: flex;
  align-items: center;
`;

const DownloadLink = ({ title, downloadId, platform, icon }) => {
  const dispatch = useDispatch();

  const userId = useSelector(getUserId);
  const activeDownloads = useSelector(currentDownloads);

  const isLoading = activeDownloads.includes(downloadId);

  const onClick = async () => {
    dispatch(setDownloadIsActive(downloadId));
    await handleDownload({ downloadId, userId });
    dispatch(setDownloadIsDone(downloadId));
  };

  return (
    <Container>
      <Icon name={icon} />
      <Link key="title">
        <div onClick={isLoading ? () => {} : onClick}>{title}</div>
      </Link>
    </Container>
  );
};

export default DownloadLink;
