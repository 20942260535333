import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { redeemCoupon } from "../../store/reducers/user/user-reducer";

import { FormInput, LabelAndError, AAButton, Label } from "../StyledElements";
import Spinner from "../shared/Spinner";

const Container = styled.div`
  /* border: 1px solid blue; */
  padding: 0 0 1rem 0;
  min-heigt: 4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: center;
  padding: 1rem 0 0 0;
`;

const ErrorRow = styled.div`
  /* border: 1px solid red; */
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

const CouponForm = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const initialValues = { coupon: "" };

  const validate = (values) => {
    // console.log("validate", JSON.stringify({ values }, null, 2));
    const errors = {};
    const couponLength = values.coupon.length;
    if (0 < couponLength && couponLength > 14)
      errors.coupon = "invalid coupon";
    setErrorMessage(!!errors.coupon ? errors.coupon : "");
    return errors;
  };

  const onSubmit = async (values, { resetForm }) => {
    const { coupon } = values;
    // console.log(`onSubmit() coupon: [${coupon}]`);
    const { errorMessage } = await dispatch(redeemCoupon(coupon));
    if (errorMessage) {
      // console.log(`onSubmit() error: [${errorMessage}]`);
      setErrorMessage(errorMessage);
    } else {
      resetForm();
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => {
          const disabled = isSubmitting || !isValid || !dirty || !!errorMessage;
          return (
            <>
              <LabelAndError
                label={"If you have a product coupon redeem it here:"}
              />
              <FormInput
                name="coupon"
                type="text"
                onChange={handleChange}
                value={values.coupon}
                className="data-hj-allow"
                minWidth="40%"
              />
              <ButtonRow>
                <AAButton
                  onClick={disabled ? () => {} : handleSubmit}
                  variant={isValid && dirty ? "success" : "outline"}
                  width="8rem"
                  disabled={disabled}
                >
                  {!isSubmitting ? "redeem coupon" : <Spinner />}
                </AAButton>
              </ButtonRow>
              <ErrorRow>
                <Label variant="error">{errorMessage}</Label>
              </ErrorRow>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CouponForm;
