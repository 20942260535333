import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { FormBox } from "../StyledElements";
import { Section, Block } from "../ResponsiveElements";

import LoginForm from "./LoginForm";

const Login = (props) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuth);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const email = query.get("email");
  const status = query.get("status");

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard/account");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const afterLogin = () => {
    navigate("/dashboard/account");
  };

  return (
    <Section background="white">
      <Block>
        <FormBox>
          <LoginForm afterLogin={afterLogin} email={email} status={status} />
        </FormBox>
      </Block>
    </Section>
  );
};

export default Login;
