import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { myCart } from "../../store/reducers/cart/cart-reducer";

import { Container, ShopAmount } from "../StyledElements";

import CartProduct from "./CartProduct";

const CartContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
  max-width: 27em;
  -webkit-box-shadow: 3px 6px 23px -4px #000000;
  box-shadow: 3px 6px 23px -4px #000000;
  flex: 2;
`;

const Icon = styled.div`
  width: 100%;
  text-align: right;
  font-size: 3rem;
`;

const Cart = (props) => {
  const cart = useSelector(myCart);
  const { total, currency, taxAmount, taxPercent } = cart;
  const taxValue = Math.round(taxAmount) * 0.01;
  const cartIsEmpty = total === 0;

  return (
    <CartContainer>
      <Icon className="material-icons">shopping_cart</Icon>
      {process.env.REACT_APP_SHOW_NEW_PRODUCT === "true" && (
        <CartProduct cart={cart} product="play_dfam" title="PlayDFAM" />
      )}
      <CartProduct cart={cart} product="obelisk" title="OBELISK" />
      <CartProduct cart={cart} product="quartz" title="QUARTZ" />
      <CartProduct cart={cart} product="effectbundle" title="EFFECT BUNDLE" />

      <Container padding="2rem 0 0 0">
        {!cartIsEmpty && (
          <ShopAmount>{`${(total * 0.01).toFixed(2)} ${currency}`}</ShopAmount>
        )}
        {cartIsEmpty && <ShopAmount>cart is empty</ShopAmount>}
      </Container>

      {!cartIsEmpty && taxAmount > 0 && (
        <Container padding="2rem 0">
          <ShopAmount fontSize="0.8rem">{`incl. ${taxPercent}% tax = ${taxValue.toFixed(
            2,
          )} ${currency}`}</ShopAmount>
        </Container>
      )}
    </CartContainer>
  );
};

export default Cart;
